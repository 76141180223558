.accessManagement-checkbox {
    width: 20px;
}

.accessManagement-text {
    width: 10%;
    text-align: center !important;
}

.manageDepartment-button {
    background-color: #fff;
    color: #3C4856;
    border: 1px solid #475467;
    border-radius: 4px;
    padding: 0px 20px;
}


@media(min-width:768px) and (max-width:1024px) {
    .managementDepartment-button {
        background-color: #1c407b;
        color: #fff;
        margin-top: 0px !important;
        margin-bottom: 8px;
        border: 1px solid #1e3760;
        border-radius: 4px;
        /* letter-spacing: 1.6px; */
        text-transform: capitalize;
        padding: 10px 14px !important;
    }
}