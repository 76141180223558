.hikemmDeviceModal-innercard {
  padding: 0px 50px 30px 50px;
}
.Proceed-payment-button {
  background: var(--unnamed-color-1c407b) 0% 0% no-repeat padding-box;
  background: #1c407b 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 12px;
  letter-spacing: 0.6px;
  color: #ffffff;
  border: 1px solid #1c407b;
  font-weight: 500;
}

.hikemmDeviceModal-card {
  height: 692px;
  width: 600px;
  box-shadow: 0px 4px 26px #888888 !important;
  border-radius: 6px !important;
  margin-left: auto;
  margin-right: auto;
  z-index: 99;
}
.more-device-contact {
  height: 542px;
  width: 600px;
  box-shadow: 0px 4px 26px #888888 !important;
  border-radius: 6px !important;
  margin-left: auto;
  margin-right: auto;
  z-index: 99;
}
.data-storage-year {
  border: 1px solid #aebcd2;
  border-radius: 4px;
  color: #ffffff;
  padding: 9px 25px;
}
.hikemmDeviceModal-titleWrap {
  padding: 0;
  background-color: #1c407b;
  padding: 16px 36px 16px 36px;
  display: flex;
  /* gap: 20px; */
  justify-content: space-between;
}

.hikemmDeviceModal-title {
  margin: 0 !important;
  font-size: 30px !important;
  color: #fff;
  font-weight: 500 !important;
}

.hikemmDeviceModal-innerWrapText {
  text-align: center;
  font-size: 25px !important;
  font-weight: 500 !important;
  padding-bottom: 40px;
}

.hikemmDeviceModal-innerButtonwrap {
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-bottom: 30px;
}

.decrementButton {
  background-color: #bfd3f3;
  border-color: #bfd3f3;
  border-radius: 5px;
  padding: 0px 10px 0px 10px;
  color: #1c407b;
  width: 45px;
  font-size: 30px;
}

.hikemmDeviceModal-incrementButton {
  width: 40px !important;
  height: 40px !important;
  font-size: 20px !important;
  padding: 0px 0px 0px 0px !important;
  background-color: #bfd3f3 !important;
}

.hikemmDeviceModal-range {
  color: #1c407b !important;
}
.hikemmDeviceModal1-range {
  color: #50C878 !important;
}
.hikemmDeviceModal-rangeNumber {
  padding-bottom: 20px;
  display: flex !important;
  justify-content: space-between !important;
}

.hikemmDeviceModal-headerWrap {
  padding: 24px 25px 24px 25px;
  text-align: center;
}

.hikemmDeviceModal-headerText {
  text-align: center;
  font-size: 18px !important;
  font-weight: 400 !important;
  /* padding-bottom: 20px; */
}

.hikemmDeviceModal-innerText {
  text-align: center;
  font-size: 18px !important;
  font-weight: 400 !important;
  padding-bottom: 20px;
}

.hikemmDeviceModal-billedBoxwrap {
  display: flex;
  gap: 20px;
  flex-direction: row !important;
  justify-content: space-between;
}

.hikemmDeviceModal-billedBox1 {
  border: 1px solid #bfd3f3;
  border-radius: 4px;
  /* padding: 24px 35px 10px 35px; */
  padding: 20px 12px 8px 12px;
  box-shadow: 0px 12px 20px #91919133;
  width: 50%;
  cursor: pointer;
}

.hikemmDeviceModal-billedinnerBox1 {
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  justify-content: center;
  gap: 14px;
  line-height: 1;
}

.hikemmDeviceModal-billedinnerBoxtext1 {
  color: #001323;
  padding-top: 20px;
  padding-bottom: 5px;
  text-align: center;
}

.hikemmDeviceModal-billedBoxTitle1 {
  text-align: center;
  color: #001323;
  font-weight: bold !important;
  padding-bottom: 10px;
}

/* =========================================== */

.hikemmDeviceModal-dropDown {
  width: 40% !important;
  border-radius: 4px;
  background-color: transparent;
  border-color: #aebcd2;
  /* color: #fff; */
  padding: 0px 0px 0px 10px;
}

.hikemmDeviceModal-dropDown:checked {
  background-color: #007bff;
  color: #fff;
}

.hikemmDeviceModal-dropDown.form-select:focus {
  border-color: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}

.form-select:focus {
  border-color: #fff;
}

.my-select {
  /* Add your styles here */
  font-size: 16px;
  /* padding: 0px; */
  border: 2px solid #ccc;
  border-radius: 5px;
  width: 200px;
  height: 40px;
  background-color: transparent;
  color: #fff;
}

/* ======================================= */

.hikemmDeviceModal-billedBox2 {
  background-color: #bfd3f3;
  border: 1px solid #bfd3f3;
  border-radius: 4px;
  /* padding: 24px 35px 10px 35px; */
  padding: 20px 12px 8px 12px;
  box-shadow: 0px 12px 20px #91919133;
  position: relative;
  width: 50%;
  cursor: pointer;
}

.hikemmDeviceModal-billedinnerBox2 {
  color: #1c407b;
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  justify-content: center;
  gap: 14px;
  line-height: 1;
}

.hikemmDeviceModal-billedinnerBoxtext2 {
  color: #1c407b;
  padding-top: 20px;
  /* padding-bottom: 5px; */
  text-align: center;
}

.hikemmDeviceModal-billedBoxTitle2 {
  text-align: center;
  color: #1c407b;
  font-weight: bold !important;
  padding-bottom: 10px;
}

.hikemmDeviceModal-billedBox2Tag {
  background-color: #50c878;
  padding: 4px 0px 4px 0px;
  /* padding: 4px; */
  /* padding: 2px 18px 2px 18px; */
  border-radius: 50px;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 8%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -12px;
  width: 60%;
}
.smartTag-custom {
  background-color: #f7c51e;
  padding: 2px 0px;
  border-radius: 100px;
  color: #001323;
  text-align: center;
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 85%;
}
.smartTag-custome-textField {
  text-align: center;
  font-size: 14px !important;
  letter-spacing: 0px !important;
  color: #001323;
  text-transform: uppercase;
  font-weight: 600 !important;
}
.hikemmDeviceModal-dropDown:focus-visible {
  outline: none;
}

.hikemmDeviceModal-dropDown option {
  border-bottom: 1px solid red !important;
}

.contactUs-button {
  background-color: #1c407b;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  border: 1px solid #1e3760;
  border-radius: 4px;
  padding: 10px;
  width: 150px;
  letter-spacing: 1.6px;
  text-transform: capitalize;
}

.Back-button {
  background-color: #e5e5e5;
  color: #1c407b;
  font-size: 20px;
  font-weight: 500;
  border: 0;
  border-radius: 4px;
  width: 150px;
  padding: 12px;
  letter-spacing: 1.6px;
  text-transform: capitalize;
}

.abc {
  position: relative;
}

.hikemmDeviceModal-footerText {
  display: flex;
  align-items: center;
  gap: 20px;
justify-content: center;
  /* position: absolute;
  bottom: 10px;
  right: 50px; */
}

.hikemmDeviceModal-footerBtnwrap {
  display: flex;
  gap: 10px;
}

.hikemmPayment-footerText {
  display: flex;
  /* align-items: center; */
  gap: 20px;
  margin-top: -15px;
  justify-content: center;
}

@media (min-width: 899.98px) and (max-width: 1024px) {
  .hikemmDeviceModal-card {
    width: auto;
    height: auto;
    position: relative;
  }
  .more-device-contact {
    width: 600px;
  }
  .hikemmDeviceModal-billedinnerBox1 {
    flex-direction: column;
    gap: 0;
  }
  .hikemmDeviceModal-billedBox1 {
    width: 100%;
  }
  .hikemmDeviceModal-billedBox2 {
    width: 100%;
  }
  .hikemmDeviceModal-billedinnerBox2 {
    width: 100%;
    flex-direction: column;
    gap: 0;
  }
  .hikemmDeviceModal-billedBoxwrap {
    flex-direction: column !important;
  }
}

@media (min-width: 1200px) and (max-width: 1900px) {
  .hikemmDeviceModal-footerText {
    position: static;
    display: flex;
    justify-content: center;
    margin-top: 20px;
 
  }
}

@media (min-width: 1024px) and (max-width: 1536px) {
  .hikemmDeviceModal-footerText {
    position: static;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .hikemmPayment-footerText {
    margin-top: 9px;
  }
}

@media (min-width: 899.99px) and (max-width: 1024px) {
  .hikemmDeviceModal-footerText {
    position: static;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

@media (min-width: 767px) and (max-width: 899.98px) {
  .hikemmDeviceModal-footerText {
    position: static;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 11px;
   
  }

  .hikemmDeviceModal-card.custom-class {
    margin-top: 20px !important;
  }
  .hikemmDeviceModal-card {
    height: 712px;
    width: 600px;
    position: relative;
  }
  .hikemmPayment-footerText {
    margin-top: 20px;
    margin-left: 20px;
  }
  .total-count {
    font-size: 44px !important;
  }
  .total-count-footer {
    font-size: 26px !important;
  }
}
