.deviceManagementHeader-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}
.deviceManagementTitle-wrap {
  display: flex;
  align-items: center;
  gap: 4px;
}
.device-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #81818129;
  border-radius: 12px;
  width: 1100px;
  height: auto;
  padding: 10px 25px;
}
.gateWayShimmer-table {
  padding: 9px 27px !important;
}

.gateWayShimmer-table td {
  padding: 11px !important;
}
.deviceManagementHeader-wrap .backArrow {
  cursor: pointer;
}

.addDevice-buttonWrap {
  display: flex;
  gap: 10px;
}

.addDevice-button {
  background-color: #1c407b;
  color: #fff;
  /* margin-top: -22px;
    margin-bottom: 6px; */
  border: 1px solid #1e3760;
  border-radius: 4px;
  /* letter-spacing: 1.6px; */
  text-transform: capitalize;
  padding: 0px 20px;
}

.filter-button {
  background-color: #1c407b;
  color: #fff;
  /* margin-top: -22px;
    margin-bottom: 6px; */
  border: 1px solid #1e3760;
  border-radius: 4px;
  /* letter-spacing: 1.6px; */
  text-transform: capitalize;
  padding: 0px 20px;
}

.removeFilter-button {
  background-color: #fff;
  color: #1c407b;
  /* margin-top: -22px;
    margin-bottom: 6px; */
  border: 1px solid #1e3760;
  border-radius: 4px;
  /* letter-spacing: 1.6px; */
  text-transform: capitalize;
  padding: 0px 20px;
}

.hikemmdevice-btn {
  background: #1c407b;
  border: 1px solid #103168;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.54px;
  color: #ffffff;
  width: 180px;
  height: 46px;
}
.vmsdevice-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #103168;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.54px;
  color: #001323;
  width: 180px;
  height: 46px;
}
.tableResponsive {
  width: 100%;
  overflow-x: auto;
  padding-top: 20px;
}
.device-alert-image {
  width: 27px;
  height: 30px;
  padding-top: 4px;
}
.deviceManagementTable {
  background: #a9b0bd;
  border: 1px solid #a9b0bd;
  border-radius: 8px;
  overflow: visible;
  position: relative;
  z-index: 0;
}
.devicemanagement-pagination {
  position: relative;
  z-index: -1;
}

.deviceManagementTable,
tr,
td,
th {
  border: 1px solid #a9b0bd !important;
}

.deviceManagementTable tr {
  background-color: #fff;
}

.deviceManagementTable th {
  /* padding: 16px 20px !important; */
  line-height: 1 !important;
  /* font-size: 18px !important; */
  font-size: 16px;
}

/* td {
    padding: 0 20px !important;
    line-height: 1 !important;
    white-space: nowrap;
    color: red !important;
} */

.addDeviceModal-card {
  height: auto !important;
  width: auto;
  /* padding: 12px 20px; */
  /* position: absolute; */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 26px #888 !important;
  border-radius: 6px !important;
  z-index: 99;
}

.filterModal-card {
  height: auto !important;
  width: 24%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 26px #888 !important;
  border-radius: 6px !important;
  z-index: 99;
}

.addDeviceModal-card-modal {
  height: auto;
  width: 441px;
  /* padding: 12px 20px; */
  /* position: absolute; */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 26px #888 !important;
  border-radius: 6px !important;
  z-index: 99;
}

.addDeviceModal-headerWrap {
  padding: 18px 25px 6px 25px;
}

.addDeviceModal-titleWrap {
  padding: 0;
  text-align: center !important;
  background-color: #1c407b;
  padding: 24px 0px 14px 0px;
  position: relative;
}
.closeImg {
  position: absolute;
  right: 20px;
  top: 25px;
}
.select-dropdown{
  padding: 6px;
  border-radius: 4px;
}

.addDeviceModal-title {
  color: #fff;
  font-weight: 500 !important;
}

.addDeviceModal-innerWrap {
  padding: 0px 25px 25px 25px;
}

.addDeviceModal-checkBoxWrap {
  display: flex;
}
.insideDeviceTable {
  background-color: white;
  padding: 0 !important;
}

tr.MuiTableRow-root.deviceManagementTable-gateway-title.MuiTableRow-head th {
  font-weight: 400 !important;
}

.btn-product {
  width: 33.33%;
  padding: 3px 0px;
  border-radius: 4px 0px 0px 4px;
  background: white;
  border: 1px solid #c6cfe3;
  font-size: 14px;
  padding-bottom: 11px;
}
.btn-product1 {
  width: 33.33%;
  padding: 3px 0px;
  border-radius: 0px;
  background: white;
  border: 1px solid #c6cfe3;
  font-size: 14px;
  padding-bottom: 11px;
}
.btn-product2 {
  width: 33.33%;
  padding: 3px 0px;
  border-radius: 0px 4px 4px 0px;
  background: white;
  border: 1px solid #c6cfe3;
  font-size: 14px;
  padding-bottom: 11px;
}

.addDeviceModal-text {
  margin-top: 11px !important;
}
.setting-icon-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.addDeviceBtn {
  background-color: #1c407b;
  color: #fff;
  border: 1px solid #1e3760;
  border-radius: 4px;
  /* width: auto; */
  width: 100%;
  padding: 12px 82px;
  margin-top: 20px;
  /* letter-spacing: 1.8px; */
  text-transform: capitalize;
}

.addDeviceBtn {
  background-color: #1c407b;
  color: #fff;
  border: 1px solid #1e3760;
  border-radius: 4px;
  /* width: auto; */
  width: 100%;
  padding: 12px 82px;
  margin-top: 20px;
  /* letter-spacing: 1.8px; */
  text-transform: capitalize;
}
.clientManagement-updateModal-data {
  position: absolute;
  /* right: 25px; */
  z-index: 999;
  width: 75px;
  /* min-height: 70px; */
  padding: 6px 0px 4px 0px;
  border: 1px solid #1c407b;
  overflow: visible !important;
  left: 0px;
  margin-top: 8px;
}

.clientMangement-updatedataWrap-data {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.deviceLastSeenModal {
  position: absolute;
  /* right: 3% !important; */
  margin-top: 7px;
  z-index: 99;
  box-shadow: 0px 0px 30px #0000008a;
  opacity: 1;
  background: white;
  height: 119px;
  width: 300px;
  text-align: center;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ================================================================== */

/* .tableResponsive {
    width: 100%;
    overflow-x: auto;
} */

.deviceManagementTable-title th {
  color: #3c4856 !important;
  font-weight: 700;
}

.deviceManagementTable-data th {
  color: #3c4856 !important;
  font-weight: normal !important;
}

.deviceManagementTable-license {
  display: flex !important;
  gap: 10px !important;
}

.deviceManagementTable-icon {
  display: flex !important;
  gap: 12px !important;
}

.cloudActiveImg {
  cursor: pointer;
}

.deleteConfirmation-Modal {
  height: auto;
  width: auto;
  /* padding: 12px 20px; */
  /* position: absolute; */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 26px #888888 !important;
  border-radius: 6px !important;
  padding: 30px 80px 30px 80px;
  z-index: 99999;
}

.deleteConfirmation-title {
  text-align: center;
  color: #1c407b;
  font-weight: 700 !important;
}

.deleteConfirmation-btnWrap {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.deleteConfirmation-Yesbtn {
  background-color: #1c407b;
  color: #fff;
  border: 1px solid #1e3760;
  border-radius: 4px;
  /* width: auto; */
  width: 50%;
  padding: 10px;
  margin-top: 20px;
  letter-spacing: 1.8px;
  text-transform: capitalize;
}

.deleteConfirmation-Nobtn {
  background-color: #a9b0bd;
  color: #001323;
  border: 1px solid #a9b0bd;
  border-radius: 4px;
  /* width: auto; */
  width: 50%;
  padding: 10px;
  margin-top: 20px;
  letter-spacing: 1.8px;
  text-transform: capitalize;
}
.title-switch {
  margin-bottom: 0px;
  margin-top: 8px;
  margin-left: 10px;
}
.deviceManagement-switch {
  margin-top: 8px !important;
}
/* ====================================== */

.search-table-row {
  position: relative;
  padding: 5px 16px 5px 40px !important;
  background: #f1f1ff;
  height: 50px;
  border: 1px solid #a9b0bd;
  border-radius: 4px;
  display: flex;
  /* min-width: 400px;
    max-width: 100%; */
}

.search-icon {
  color: #90a3b9;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 24px !important;
  width: 24px !important;
}

.search-table-row input {
  background: transparent;
  border: 0;
  min-width: 80px;
  width: 100%;
}

.search-table-row input:focus {
  outline: none;
}

.deviceManagementTable tr:hover {
  background-color: #fff !important;
}

.deviceManagementTable tr:nth-child(even) {
  background-color: #f1f1ff !important;
}

.MuiInput-underline:before {
  border-bottom: 0px !important;
}

.MuiInput-underline:after {
  border-bottom: 0px !important;
}
.product_counts_popup {
  position: absolute;
  left: 71%;
  z-index: 1;
  width: 200px;
  /* min-height: 70px; */
  padding: 6px 14px 4px 14px;
  border: 1px solid #1c407b;
  overflow: visible !important;
  /* left: 20px; */
  margin-top: 8px;
  margin-right: 10px;
  color: #1c407b !important;
  font-size: 20px !important;
}
.react-switch-handle {
  top: 1px !important;
}

@media (max-width: 1024px) {
  .product_counts_popup {
 left : 30%
  }
}
/* .product_counts_popup:hover{
   visibility: visible;
   display: block;
} */
.OutlineIcon {
  transform: rotate(180deg);
}
.product-count-main-container {
  display: flex;
  justify-content: space-between;
  position: relative;
}
@media (max-width: 1400px) {
  .addDeviceModal-card {
    /* border-radius: 6px!important;
        box-shadow: 0 4px 26px #888!important;
        height: auto;
        left: 50%;
        position: absolute; */
    /* top: 76%; */
    /* -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: auto;
        z-index: 99; */
  }
}

@media (min-width: 899.98px) and (max-width: 1024px) {
  .addDeviceModal-card {
    width: auto;
    height: auto;
    top: 50%;
  }
  .addDeviceBtn {
    width: 100%;
    padding: 14px;
  }
}

@media (min-width: 767px) and (max-width: 899.98px) {
  .addDeviceModal-card {
    width: auto;
    height: auto;
    /* top: 50%; */
  }
  .addDeviceBtn {
    width: 100%;
    padding: 14px;
  }
  .btn-product {
    width: 39.33% !important;
  }
  .deviceManagement-switch {
    margin-right: 15px !important;
  }
}

@media (max-width: 767.98px) {
  /* .page-header .header-btn-group {
    width: 100%;
  } */
  .search-table-row {
    order: 5;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .MuiInputBase-input {
    min-width: 35px !important;
  }
  .MuiInputBase-input {
    min-width: 35px !important;
  }
}

@media (max-width: 599.98px) {
  .MuiInputBase-input {
    min-width: 35px !important;
  }
}

/* @media (max-width: 1499.98px) {
    .MuiInputBase-input {
        min-width: 35px !important;
    }
    .search-table-row input {
        font-size: 14px;
    }
} */
@media (min-width: 768px) and (max-width: 1024px) {
  .device-box {
    width: auto;
  }
  .hikemm-device-box {
    justify-content: center;
    gap: 34px;
  }
  .bridgeDevice-headerContent {
    margin-top: 19px;
  }
  .deviceManagementHeader-wrap {
    flex-wrap: wrap;
    gap: 15px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .deviceManagementHeader-wrap {
    flex-wrap: wrap;
    gap: 15px;
  }
}
@media (min-width: 1200px) and (max-width: 1536px) {
  .addDeviceModal-card {
    height: auto;
    overflow: auto !important;
  }
  .device-box {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 12px #81818129;
    border-radius: 12px;
    width: 869px;
    height: auto;
    padding: 10px 25px;
  }
  .product_counts_popup {
    left: 56%;
  }
}
