.selection-action-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #a9b0bd;
  margin: 5px 0px;
}
.service-call-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #475467;
  border-radius: 4px;
  padding: 14px;
}
.serviceCallCard {
  width: 383px;
  height: 441px;
  padding: 25px 20px;
}
.serviceCallData {
  position: fixed;
  top: 35%;
  left: 42%;
  transform: translate(-50px, -50px);
  z-index: 999;
  box-shadow: 0px 0px 30px #0000008a;
  border-radius: 6px;
}
.selection-action-dropdown-1 {
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #000000;
}
.cancleButton {
  background: #ffff;
  border: 1px solid #1e3760;
  border-radius: 4px;
  padding: 10px 41px;
  margin-right: 10px;
}
.submitButton {
  background: #1c407b;
  border: 1px solid #1e3760;
  border-radius: 4px;
  padding: 10px 41px;
  color: white;
}
.serviceCall-btn {
  text-align: center;
}
.serviceCallCard h6 {
  font-size: 14px;
  color: #001323;
}

@media only screen and (min-width: 1024px) and (max-width: 1536px) {
    .serviceCallData {
        position: fixed;
        top: 35%;
        left: 42%;
        transform: translate(-50px, -50px);
        z-index: 999;
        box-shadow: 0px 0px 30px #0000008a;
        border-radius: 6px;
      }
}
@media only screen and (min-width: 768px) and (max-width: 1024px){
    .serviceCallData {
        position: fixed;
        top: 35%;
        left: 36%;
        transform: translate(-50px, -50px);
        z-index: 999;
        box-shadow: 0px 0px 30px #0000008a;
        border-radius: 6px;
      }
}
