/* PieChartShimmer.css */
.pie-chart-shimmer {
    background: conic-gradient(#e0e0e0 25%, #f0f0f0 25% 50%, #e0e0e0 50% 75%, #f0f0f0 75%);
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    width: 200px; /* Adjust based on your preference */
    height: 200px; /* Adjust based on your preference */
    z-index: 1;
  }
  
  .pie-chart-shimmer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
    background-repeat: no-repeat;
    background-size: 200% 100%; /* Ensures gradient is wide enough to cover the element */
    animation: shimmerAnimation 2s infinite linear;
  }
  
  @keyframes shimmerAnimation {
    0% {
      background-position: -100%; /* Start from the left */
    }
    100% {
      background-position: 100%; /* Move to the right */
    }
  }
  
  .center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; Adjust based on your container's desired height */
  }
  