.addCompany-button {
    background-color: #1C407B;
    color: #fff;
    border: 1px solid #1E3760;
    border-radius: 4px;
    width: 150px;
    text-transform: capitalize;
    padding: 8px;
}

.texteArea-text {
    position: absolute;
}

.companyDetails {
    display: flex;
    justify-content: space-between !important;
    /* border-right: 2px solid #C9CFE7; */
}

.clientManagement.searchbarWrap {
    padding-top: 10px;
    padding-bottom: 0px;
}

.clientManagement.searchbar {
    background-color: #fff;
    /* padding: 10px 0px 10px 0px; */
}

.clientManagement.searchbar::placeholder {
    color: #90A3B9;
}

.clienManagementBorder {
    border-right: 2px solid #C9CFE7;
    position: absolute;
}

.companyTitle {
    display: flex;
    flex-direction: column;
    color: #001323;
}

.expandCompanyBorder {
    border-left: 1px solid #C9CFE7;
    /* padding-left: 40px; */
    height: 50px;
}

.clientManagement.cardContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.clientManagement.cardContent1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.CoinManagment-detailsIcon {
    cursor: pointer;
    position: relative;
    /* position: absolute; */
    /* top: 9px; */
    /* right: -8px; */
}

.clientManagement.companyText {
    color: #001323;
    /* font-size: 20px !important; */
    font-size: 13px !important;
    font-weight: 500 !important;
    margin: 0;
}

.clientManagement.companyNumber1 {
    color: #50C878;
    /* font-size: 30px !important; */
    font-size: 20px !important;
    font-weight: 500 !important;
}

.clientManagement.companyNumber2 {
    color: #E31E24;
    /* font-size: 30px !important; */
    font-size: 20px !important;
    font-weight: 500 !important;
    /* border-right: 1px solid black; */
}

.clientManagement-companySmalltext {
    color: #5473A5;
    font-size: 8px !important;
}

.clientMangement-downIcon {
    display: flex;
    margin: 0 auto;
    cursor: pointer;
}

/* .cardCompany::before {
    content: "";
    display: block;
    border-left: 1px solid #C9CFE7;
    height: 20%;
    position: absolute;
    left: 47%;
} */

.cardCompany {
    padding: 24px 10px 0px 10px;
    position: relative;

    overflow: visible;
    /* z-index: -999; */
    /* z-index: auto; */
}

.clientManagement-updateModal {
    position: absolute;
    /* top: 38%; */
    /* top: 65% !important; */
    right: 0;
    /* right: 25px; */
    z-index: 999;
    width: 75px;
    /* min-height: 70px; */
    padding: 6px 0px 4px 0px;
    border: 1px solid #1C407B;
    overflow: visible !important;
}

.top::after {
    content: '';
    height: 12px;
    width: 12px;
    position: absolute;
    background-color: #fff;
    top: -7px;
    left: 45%;
    border-top: black solid 1px;
    border-left: black solid 1px;
    transform: rotate(45deg);
}


/* .clientManagement-updateModal::before {
    content: '';
    display: block;
    position: absolute;
    top: -25%;
    right: 28px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 16px solid red;
    z-index: 2;
} */

.clientMangement-updatedataWrap {
    display: flex;
    flex-direction: column;
}

.clientManagement-updateData {
    border-bottom: 1px solid #1C407B;
    padding: 0px 6px 0px 6px;
    display: flex;
    gap: 10px;
    cursor: pointer;
}

.clientManagement-deleteData {
    padding: 6px 6px 0px 4px;
    width: fit-content;
    display: flex;
    gap: 10px;
    cursor: pointer;
}

.clientMangement-editIcon {
    width: 16px;
    height: 16px;
}

.clientMangement-editText {
    color: #001323;
}

.userAccessTab {
    padding: 20px 0px 0px 0px !important;
}

.userAccsessTab-text {
    color: #001323;
    margin-bottom: 20px !important;
}

.userAccessTab-inviteTab {
    background-color: #1C407B;
    border: 1px solid #1E3760;
    color: #fff;
    font-size: 14px;
    border-radius: 3px;
    padding: 6px 20px 6px 20px;
}

.plantTab {
    padding: 20px 0px 0px 0px !important;
}

.plantTab-text {
    color: #001323;
    margin-bottom: 20px !important;
}

.plantTab-btn {
    background-color: #1C407B;
    border: 1px solid #1E3760;
    color: #fff;
    font-size: 14px;
    border-radius: 3px;
    padding: 6px 20px 6px 20px;
}

.deviceTab {
    padding: 20px 0px 0px 0px !important;
}

.deviceTab-text {
    color: #001323;
    margin-bottom: 20px !important;
}

.css-1x0awbg {
    background-color: #F4F7FE !important;
}

.deviceTab-btn {
    background-color: #1C407B;
    border: 1px solid #1E3760;
    color: #fff;
    font-size: 14px;
    border-radius: 3px;
    padding: 6px 20px 6px 20px;
}

.clientManagement-disc {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
}

.clientManagement-discription {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.css-1sw33w0-MuiStack-root>:not(style)+:not(style) {
    /* margin: 0;
    margin-top: 16px; */
    /* background-color: #F4F7FE !important; */
}

.css-85zwa9-MuiInputBase-root-MuiFilledInput-root {
    background-color: #F4F7FE !important;
}

.inviteUserModal {
    /* height: auto; */
    /* height: 525px; */
    min-height: 525px;
    width: auto;
    padding: 12px 20px;
    /* position: absolute; */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 26px #888888 !important;
    z-index: 1;
}

.ClientManagement.InviteUser-Button {
    background-color: #1C407B;
    color: #fff;
    border: 1px solid #1E3760;
    border-radius: 3px;
    /* width: auto; */
    margin: 0 auto;
    display: flex;
    width: 55%;
    padding: 6px 44px;
    margin-top: 20px;
    /* margin-bottom: 10px; */
    text-align: center;
    text-transform: capitalize;
}

.userInviteLabel {
    font-size: 12px;
    color: #001323;
    margin-bottom: 0px;
}

.useInviteWrap {
    margin-bottom: 6px;
}

.userInviteLabelplaceHolder::placeholder {
    font-size: 12px;
    color: #001323;
}

.userAccessTabDataList {
    display: flex;
    gap: 10px;
}

.userAccessTabDataName {
    color: #001323;
    font-weight: 700 !important;
}

.clientManagement.plantTabListCard {
    padding: 6px 0px 8px 10px;
}

.clientManagement.deviceTabListCard {
    padding: 6px 0px 8px 10px;
}

.clientManagement.plantTabListCardWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.clientManagement.deviceTabListCardWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.clientMangement.plantTabList-ManageButton {
    background-color: #1C407B;
    color: #fff;
    border: 1px solid #1E3760;
    border-radius: 4px;
    width: 120px;
    text-transform: capitalize;
    padding: 8px;
    margin-right: 22px;
}

.plantTabListWrap {
    display: flex;
    gap: 20px;
}

.deviceTabListWrap {
    display: flex;
    gap: 20px;
}

.clientManagement.plantTabListImgWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.clientManagement.deviceTabListImgWrap {
    display: flex;
    flex-direction: column;
    gap: 0px;
    /* margin-bottom: 0px !important; */
}

.clientManagement.deviceTabListText {
    font-size: 12px;
    font-weight: 500 !important;
    margin-bottom: 0px;
}

.clientManagement.deviceTabListText.small {
    color: #595a5c;
    font-size: 10px;
    margin-bottom: 0px;
}


.clientManagement.plantTabListTextWrap {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.clientManagement.deviceTabListTextWrap {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.clientManagement.plantTabList.plantName {
    margin-bottom: 0px;
    font-weight: 600 !important;
}

.clientManagement.deviceTabList.deviceName {
    margin-bottom: 0px;
    font-weight: 600 !important;
}

.clientMangement.plantTabList-ButtonWrap {
    display: flex;
    gap: 6px;
}

.clientMangement.deviceTabList-ButtonWrap {
    display: flex;
    gap: 6px;
}

.clientManagemet.plantTablist-Button {
    background-color: #50C878 !important;
    border: 0 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #001323 !important;
    border-radius: 2px !important;
    padding: 4px 16px 4px 16px !important;
}

.clientManagemet.plantTablist-Button.inActive {
    background-color: #DDDDEE !important;
    border: 0 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #001323 !important;
    border-radius: 2px !important;
    padding: 4px 16px 4px 16px !important;
}

.clientManagemet.deviceTablist-Button {
    background-color: #DDDDEE;
    border: 0;
    font-size: 12px;
    font-weight: 500;
    color: #1C407B;
    border-radius: 2px;
    padding: 4px 16px 4px 16px;
}

@media (min-width: 1800px) and (max-width: 1920px) {
    /* .clientManagement-updateModal {
        top: 24%;
    } */
}

@media (min-width: 1600px) and (max-width: 1799.98px) {
    /* .clientManagement-updateModal {
        top: 22%;
    } */
}

@media (min-width: 1400px) and (max-width: 1599.98px) {
    /* .clientManagement-updateModal {
        top: 24%;
    } */
}

@media (min-width: 1199px) and (max-width: 1399.98px) {
    /* .clientManagement-updateModal {
        top: 26%;
    } */
}

@media (min-width: 1024px) and (max-width: 1199.98px) {
    /* .clientManagement-updateModal {
        top: 26%;
    } */
    .expandCompanyBorder{
        /* border-left: none;
        height: 0; */
    }
}

@media (min-width: 899px) and (max-width: 1024px) {
    .inviteUserModal {
        width: 400px;
        /* top: 75%; */
    }
    /* .expandCompanyBorder{
        display: none !important;
    } */

    .ClientManagement.InviteUser-Button {
        width: 46%;
    }

    /* .clientManagement-updateModal {
        top: 26%;
    } */
    .clientManagement-updateModal {
        top: 68% !important;
        right: 3px;
    }
}

@media (min-width: 767px) and (max-width: 899.98px) {
    .cardCompany::before {
        display: none;
    }
    .expandCompanyBorder{
        /* display: none !important; */
        border-left: none;
        height: 0;
    }

    .inviteUserModal {
        width: 400px;
        /* top: 75%; */
        /* min-height: 400px; */
    }

    .ClientManagement.InviteUser-Button {
        width: 46%;
    }

    .clientManagement.companyText {
        font-size: 16px !important;
    }

    .clientManagement-companySmalltext {
        color: #5473A5;
        font-size: 12px !important;
    }

    /* .clientManagement-updateModal {
        top: 32%;
    } */
    .clientManagement-updateModal {
        top: 82% !important;
        right: 3px;
    }
}
@media (min-width: 576px) and (max-width: 899.98px) {
    .expandCompanyBorder{
        /* border-left: none;
        height: 0; */
    }
}