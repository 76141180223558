.userManagementTable {
  padding-top: 20px;
}
/* .updateUserDropdow.form-select {
    border: 1px solid #000 !important;
    font-size: 12px !important;
} */
.createAdmin {
  margin-bottom: 0;
  margin-top: 5px;
  background: #e8f8f2;
  color: #00ab66;
  padding: 6px 16px;
  border-radius: 16px;
  border: 1px solid #00ab66;
}
.createAdminDeleteUser {
  height: 400px;
  width: 634px;
  /* padding: 12px 20px; */
  /* position: absolute; */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 26px #888888 !important;
  border-radius: 6px !important;
  padding: 30px 20px 30px 20px;
  z-index: 99;
}
.adminNextButton {
  float: right;
  padding: 5px 20px;
  background: #1c407b;
  color: white;
  border: none;
  border-radius: 4px;
}
.createCloseIcon{
  margin-top: -22px !important;
  margin-right: -8px !important;
}
.admindisableNextButton{
  float: right;
  padding: 5px 20px;
  background: #1c407b;
  color: white;
  border: none;
  border-radius: 4px;
  opacity: 0.5;
  pointer-events: none;
}
.adminUserCard {
  height: 286px;
  flex-wrap: wrap;
  overflow: auto;
}
.adminUser {
  margin-bottom: 0;
  margin-top: 5px;
  background: #ededed;
  color: #667d73;
  padding: 6px 16px;
  border-radius: 16px;
  border: 1px solid #667d73;
}
.user-item {
  margin-bottom: 10px; /* Add margin-bottom for vertical spacing */
}

.user-name {
  margin-left: 10px; /* Add margin-left for spacing between checkbox and name */
}
.updateUserDropdown {
  border: 1px solid #000;
  font-size: 14px;
  padding: 10px;
}
.updateUserDropdown option {
  font-size: 16px;
}
.form-select-lg.updateUserDropdow {
  border: 1px solid #000 !important;
  font-size: 12px !important;
}
.updateUserDropdown.form-select:focus {
  box-shadow: none !important;
  border: 1px solid #000;
}
.ClientManagement.InviteUser-Button.userManagement {
  padding: 6px 56px;
}

.disabledRow {
  pointer-events: none; /* Disable pointer events */
}
