/* TextShimmer.css */
@keyframes textShimmerAnimation {
    0% { background-position: -200% 0; }
    100% { background-position: 200% 0; }
  }
  
  .text-shimmer {
    width: 100%;
    background-color: #f6f7f8;
    border-radius: 4px;
    margin: 8px 0;
    animation: textShimmerAnimation 1.5s infinite linear forwards;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 50%, #f6f7f8 100%);
    background-size: 200% 100%;
    background-repeat: no-repeat;
  }
  