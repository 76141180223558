.cancelButton {
    background-color: #F4F7FE;
    color: #001323;
    border: 1px solid #1E3760;
    border-radius: 6px;
    width: 200px;
    /* width: 50%; */
    padding: 12px 48px;
    letter-spacing: 0.8px;
    text-transform: capitalize;
}

.updateButton {
    background-color: #1C407B;
    color: #fff;
    border: 1px solid #1E3760;
    border-radius: 6px;
    width: 200px;
    /* width: 50%; */
    padding: 16px 48px;
    letter-spacing: 0.8px;
    text-transform: capitalize;
}

.customeTextField label {
    color: #001323;
    font-weight: 600;
}
.customeTextField.personal label{
    color: #A9B0BD;
    font-weight: 400;
}
.customeTextField.security label{
    color: #A9B0BD;
    font-weight: 400;
}

.userpofileCard {
    /* height: 750px; */
    min-height: 750px;
    position: relative;
}

.userId {
    padding-top: 90px;
}

.profileButton-wrap {
    position: absolute;
    bottom: 117px;
    display: flex;
    justify-content: center;
    padding-left: 50%;
    /* padding-left: 65%; */
}
.profileButton-wrap.personalTab{
    bottom: 60px;
    padding-left: 66%;
}
.profileButton-wrap.securityTab{
    bottom: 60px;
    padding-left: 60%;
}
.profileButton-wrap.communicationTab{
    bottom: 60px;
    padding-left: 60%;
}

label[data-shrink=false]+.MuiInputBase-formControl .css-10botns-MuiInputBase-input-MuiFilledInput-input {
    background-color: #F4F7FE;
}

label[data-shrink=false]+.MuiInputBase-formControl .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input {
    background-color: #F4F7FE;
}

label[data-shrink=false]+.MuiInputBase-formControl .css-1gctnaj-MuiInputBase-input-MuiFilledInput-input {
    background-color: #F4F7FE;
}

.css-zs07ro-MuiInputBase-root-MuiFilledInput-root {
    background-color: #F4F7FE !important;
}
.css-10botns-MuiInputBase-input-MuiFilledInput-input{
    background-color: #F4F7FE !important;
}
.css-llrb4p-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root.Mui-focused {
    background-color: #F4F7FE !important;
}
.css-1kqdz9i-MuiInputBase-root-MuiFilledInput-root{
    background-color: #F4F7FE !important;
}




/* .css-heg063-MuiTabs-flexContainer {
    gap: 48px;
} */

.css-1aquho2-MuiTabs-indicator {
    background-color: transparent !important;
}
@media(min-width:1025px) and (max-width:1260px){
    .profileButton-wrap.personalTab{
        margin: 0 auto;
        /* margin-right: 21%; */
        padding-left: 8% !important;
        padding-top: 20px;
        position: unset;
    }
}

@media(min-width:899.98px) and (max-width:1024px) {
    .profileButton-wrap {
        /* bottom: 0px; */
        /* padding-left: 43%; */
        /* justify-content: end; */
        /* padding-left: 20%; */
        position: relative;
        margin-top: 200px !important;
    }
    .profileButton-wrap.personalTab{
        margin: 0 auto;
        margin-right: 21%;
        padding-left: 0% !important;
        padding-top: 20px;
        /* position: unset; */
    }
}

@media (min-width: 767px) and (max-width: 899.98px) {
    .userpofileCard {
        /* height: 800; */
        /* height: 100%; */
    }
    .profileButton-wrap {
        /* position: relative; */
        margin-top: 145px;
        /* bottom: -60px; */
        /* padding-left: 20%; */
        /* justify-content: center !important; */
    }
    .profileButton-wrap.personalTab{
        margin: 0 auto;
        margin-right: 21%;
        padding-left: 0% !important;
        padding-top: 20px;
        position: unset;
    }
    .profileButton-wrap.securityTab{
        padding-left: 22% !important;
    }
    .profileButton-wrap.communicationTab{
        /* bottom: 60px; */
        padding-left: 22% !important;
    }
    .cancelButton {
        width: 50%;
    }
    .updateButton {
        width: 50%;
    }
}