
@keyframes shimmerAnimation {
    0% {
      background-position: 0 -468px;
    }
    100% {
      background-position: 0 468px;
    }
  }
  
  .verticalBarShimmerContainer {
    display: flex;
    align-items: flex-end;
    height: 400px; /* Adjust based on your preference */
    width: 100%;
    gap: 40px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff; /* Background of the chart */
  }
  
  .verticalBarShimmer {
    width: 20%; /* Adjust based on the number of bars and container width */
    height: 60%; /* Initial height, adjust each bar individually */
    background-color: #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
  }
  
  .verticalBarShimmer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(transparent, rgba(255, 255, 255, 0.8), transparent);
    background-size: 100% 800px;
    animation: shimmerAnimation 2s infinite;
  }
  





@keyframes shimmerAnimation {
  0% {
    background-position: 0 -468px;
  }
  100% {
    background-position: 0 468px;
  }
}

.small-verticalBarShimmerContainer {
  display: flex;
  align-items: flex-end;
  height: 350px; /* Adjust based on your preference */
  width: 100%;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #ffffff; /* Background of the chart */
}

.small-verticalBarShimmer {
  width: 20%; /* Adjust based on the number of bars and container width */
  height: 60%; /* Initial height, adjust each bar individually */
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.small-verticalBarShimmer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(transparent, rgba(255, 255, 255, 0.8), transparent);
  background-size: 100% 800px;
  animation: shimmerAnimation 2s infinite;
}
