/* FileName: App.css */

.App {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5%;
    margin-top: 10px;
}

.calc-title {
    color: #103168;
    margin-bottom: 30px;
    /* Adjusted margin */
    font-size: 1.875rem ;
    font-weight: 700;
}

.Button_disable{
  background-color: #1c407ba6 !important;
  border: 1px solid  #1c407ba6 !important;
}

.calc-body {
    background-color: #ffffff;
    border: 1px solid #aeaccf;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 60vw;
    /* Adjusted container width */
    padding: 2vw;
    /* Adjusted padding */
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 360px;
}

.scientific-calc-btn {
  background: #1c407b;
  border-radius: 2px;
  color: white;
  padding: 8px 14px;
  border: 1px solid #1c407b;
  border-radius: 5px;
}

.input-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.screen {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
}


/* button {
    padding: 5px 10px;
    font-size: 16px;
    font-weight: 600;
    background-color: #b8bcb9;
    color: #000000;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
} */

/* .equals-button {
    background-color: #ff5733;
  
    margin: 5px;
}

.clear-button {
    background-color: #ff5733;

    margin: 5px;
}

.backspace-button {
    background-color: #1f73b3;
  
    margin: 5px;
} */

.variables {
    margin-bottom: 5%;
    color: #fff;
    background-color: #272727;
    flex-basis: 10%;
}

.variables h2 {
    color: #fff;
}

.fields input {
    background-color: #e8eef5;
    border: 1px solid rgb(231, 228, 228);
    width: 5vw;
}

ul {
    list-style: none;
  }
  /* .list {
    width: 100%;
    background-color: #ffffff;
    border-radius: 0 0 5px 5px;
  } */
 


  .dd-menu {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    margin: 0 0 5px 0;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.1);
    background-color: #ffffff;
    list-style-type: none;
    max-height: 90px;
    overflow: auto;
  }
  
  .dd-input + .dd-menu {
    display: none;
  } 
  
  .dd-input:checked + .dd-menu {
    display: block;
  } 
  
  .dd-menu li {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
  }
  
  .dd-menu li:hover {
    background-color: #f6f6f6;
  }
  
  .dd-menu li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
  }
  
  .dd-menu li.divider{
    padding: 0;
    border-bottom: 1px solid #cccccc;
  }



/* .sendingdataaaa1{
  display: flex;
  position: fixed;
  justify-content: center;
    align-items: center;
    position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  z-index: 2;
  pointer-events: auto;
} */
  /* .select-cal-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    z-index: 100;
  } */