/* .deviceModal-popup {
  width: 1024px;
  overflow: auto;
  height: 670px;
  background-color: white;
  position: fixed;
  top: 0%;
  left: 0%;
  transform: translate(-50px, -50px);
  z-index: 99;
  box-shadow: 0px 0px 30px #0000008a;
  border-radius: 6px;
  padding: 20px;
} */

.overlay123 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.deviceModal-popup {
  width: 50%;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 12px 40px #1E1E1E82;
}

.configration-details {
  max-height: 425px;
  overflow: auto;
}

.configration-details-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.device-tag-list {
  margin-top: -28px;
}

.shimmer-table-row--header {
  display: none;
  padding: 0px;
}

.shimmer-table-row {
  margin-bottom: 3px;
  padding: 6px 0 !important;
}

.ShimmerTable-configration-table-TagList {
  padding: 10px !important;
  padding-right: 5px !important;
}

.Tags-DataNotFound {
  padding: 10px !important;
  padding-right: 5px !important;
  font-size: 17px !important;
  background-color: white !important;
}

.devicemodal-box {
  height: 495px;
  background-color: #f1f1ff;
  padding: 20px;
}

.devicemodal-box1 {
  height: 495px;
  background-color: #f1f1ff;
  padding: 20px;
}

.devices-text h6 {
  font-size: 16px;
  color: #001323;
  font-weight: 700;
}

.devices-text {
  justify-content: space-between;
}

.selectionText {
  background-color: #ddddee;
  color: #e31e24;
  font-size: 14px;
  padding: 6px;
  border-radius: 2px;
}

.Device-caption {
  background-color: #fff;
  padding: 8px 13px;
  font-size: 15px;
}

.Device-caption1 {
  background-color: #1c407bc2;
  color: #fff;
  padding: 8px 13px;
  font-size: 15px;
}

.Device-caption p {
  margin-bottom: 0 !important;
  font-size: 13px;
}

.Device-caption h6 {
  margin-bottom: 3px !important;
  font-size: 15px;
  letter-spacing: 0.48px;
}

.device-table td {
  padding: 10px;
  font-size: 12px;
  letter-spacing: 0;
  padding-left: 5px !important;
  background-color: #f5f5f5;
}

.device-table th {
  padding: 10px;

}

.device-table {
  border-collapse: separate !important;
}

.device-table thead {
  position: sticky;
  top: 0;
  z-index: 999;
}

.selectTagDesc {
  flex-wrap: wrap;
}

.selectTagDesc h6 {
  font-size: 15px;
  font-weight: 600;
}

.selectTagDesc p {
  font-size: 13px;
  margin-left: 8px;
  margin-bottom: 10px !important;
}

.canclebtn {
  background: #ffffff;
  border: 1px solid #1e3760;
  border-radius: 4px;
  padding: 10px 50px;
}

.submit-btn-disable {
  opacity: 0.5;
  pointer-events: none;
  background-color: #1e3760;
  color: #ffffff;
  border-radius: 4px;
  padding: 15px;
  border: 1px solid #1e3760;
  padding: 10px 50px;
}

.submitbtn {
  background-color: #1e3760;
  color: #ffffff;
  border-radius: 4px;
  padding: 15px;
  border: 1px solid #1e3760;
  padding: 10px 50px;
}


.activeDeviceimges {
  position: absolute;
  z-index: 9999;
  right: 60%;
  top: 82px;
  height: 75px;
}

@media(min-width: 960px) and (max-width: 1199px) {
  .deviceModal-popup {
    width: 50%;
  }

  .configration-details {
    overflow: auto;
  }
}


@media(max-width: 959px) {
  .deviceModal-popup {
    width: 50%;
  }

  .configration-details {
    max-height: 168px;
    overflow: auto;
  }

  .devicemodal-box {
    height: auto;
  }

  .devicemodal-box1 {
    height: auto;
  }

  .device-tag-list {
    margin-top: 0px;
  }
}

@media(max-width:767px) {
  .deviceModal-popup {
    width: 90%;
  }

  .configration-details {
    max-height: 168px;
    overflow: auto;
  }

  .devicemodal-box {
    height: auto;
  }

  .devicemodal-box1 {
    height: auto;
  }

  .device-tag-list {
    margin-top: 0px;
  }
}


@media(max-width:576px) {
  .deviceModal-popup {
    width: 90%;
  }

  .configration-details {
    max-height: 168px;
    overflow: auto;
  }

  .devicemodal-box {
    height: auto;
  }

  .devicemodal-box1 {
    height: 300px;
    overflow: auto;
  }

  .configration-details-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }

  .canclebtn {
    font-size: 12px;
    letter-spacing: 0.5px;
  }

  .submitbtn {
    font-size: 12px;
    letter-spacing: 0.5px;
  }

  .selectTagDesc h6 {
    font-size: 12px;
  }

  .device-tag-list {
    margin-top: 0px;
  }
}