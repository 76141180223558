.smartTagUpArrow {
  display: flex;
}
.activepower-1 {
  display: flex;
  justify-content: space-between;
}
img.smartagimg {
  width: 45px;
  height: 42px;
  margin-top: 5px;
}
.highlight-title{
  background-image: linear-gradient(to right top, #d4d4d4, #dfdfdf, #e9e9e9, #f4f4f4, #ffffff)
}
.active_power1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 12px #00000033;
  border-radius: 6px;
  opacity: 1;
}
.BPCL_weatherchart {
  display: flex;
  justify-content: space-between !important;
}
.amb_BPCL {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #475467;
  opacity: 1;
  width: 124px;
  height: 37px;
}
.BPCL_invert {
  display: flex; /* Ensure items are in a row */
  justify-content: space-between; /* Distribute space evenly */
  align-items: center; /* Center items vertically */
  padding: 10px; /* Optional padding for button */
  border: 1px solid #a9b0bd; /* Example border styling */
  background-color: white;
  border-radius: 6px; /* Rounded corners */
}
.average_buttons {
  display: flex;
  gap: 7px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #475467;
  opacity: 1;
  /* width: 210px; */
  height: 37px;
  padding: 8px;
  border-radius: 6px;
}
span.plant_hrs {
  font-size: 13px;
}

.averagebuttons {
  margin-top: -9px;
}
.average-rate {
  font-size: 10px;
  margin-top: 7px;
}

@media (min-width: 1024px) and (max-width: 1536px) {
  /* .BPCLavailability1 {
            width: 134px;
            height: 71px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 6px 12px #EBEBEB;
            border: 1px solid #A9B0BD;
            border-radius: 6px;
            opacity: 1;
        } */
  .spacing_BPCL {
    display: flex;
    /* gap: 50px; */
  }
  .BPCLavailability_weather {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #a9b0bd;
    border-radius: 6px;
    opacity: 1;
    width: 100px !important;
    height: 66px;
  }
  .genration_bpcl {
    display: flex;
    gap: 40px;
    margin-left: 20px;
  }
  span.plant_hrs {
    font-size: 10px;
    margin-top: 7px;
  }
}

@media (min-width: 1537px) and (max-width: 1920px) {
  .genration_bpcl {
    display: flex;
    gap: 40px;
    margin-left: 20px;
  }
  .inverter_grid {
    display: flex;
    gap: 52px;
    margin-left: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1360px) {
  .genrationdunut {
    width: 200px !important;
    margin-bottom: 20px !important;
  }
  .invertersgenration {
    margin-top: 74px !important;
  }
}
