/* DeviceListShimmer.css */
@keyframes shimmerAnimation {
    0% { background-position: -100% 0; }
    100% { background-position: 100% 0; }
  }
  
  .device-list-shimmer {
    display: flex;
    flex-direction: column;
    width: 100%; /* Responsive width */
    max-width: 438px; /* Maximum width */
    height: auto; /* Height will be determined by content */
    /* padding: 0.5rem; */
  }
  
  .device-item-shimmer {
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 0.5rem;
    animation: shimmerAnimation 1.5s infinite linear forwards;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 50%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 200% 100%;
    display: flex;
    align-items: center;
    padding: 0.7rem;
    min-height: 35px; /* Minimum height for each item */
    width: 100%
  }
  
  .device-text-shimmer {
    flex-grow: 1;
    height: 12px;
    background: #e0e0e0;
    border-radius: 4px;
    margin-right: 1rem;
  }
  
  .device-status-shimmer {
    width: 24px;
    height: 24px;
    background: #e0e0e0;
    border-radius: 50%;
  }
  
  @media (min-width: 768px) { /* iPads and larger tablets */
    .device-item-shimmer {
      width: 719px; /* Specific width for iPad */
    }
  }
  
  @media (min-width: 992px) { /* Desktops */
    .device-item-shimmer {
      width: 100%; /* Or any other proportion or fixed width */
    }
  }
  
  @media (min-width: 1200px) { /* Large desktops */
    .device-item-shimmer {
      /* Adjust the width as needed, or keep it at 90% */
    }
  }