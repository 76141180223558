.addAccessManagement.selectDropDown {
    width: 90%;
    padding: 10px 0px 10px 0px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.addAccessManagement.selectDropDown.Roles {
    width: 50%;
    padding: 10px 0px 10px 0px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.accessDepartment-textWrap {
    width: 90%;
    display: flex;
    justify-content: space-between;
}

.addAccessManagement-fieldWrap {
    /* padding: 12px; */
    /* padding: 9px; */
    padding: 6px 0px 6px 0px;
    /* height: 40px; */
    /* width: 100%; */
    width: 90%;
    background-color: #F1F1FF;
    position: relative;
    cursor: pointer;
    /* color: #F1F1FF; */
}

.activeAccessDepartmentImg {
    position: absolute;
    right: -4px;
    top: 0px;
    height: 54px;
}

.accessManagement.subModules {
    display: flex;
    align-items: center;
    gap: 20px;
}

.accessManagement.switchWrap {
    width: 20%;
}

.company-plant-box-wrap {
    display: flex;
    align-items: center;
}

.selected-plant-wrap {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    /* height: 115px; */
    overflow: auto;
}

.company-up-arrow {
    background-color: #fff;
}

.company-down-arrow {
    background-color: #fff;
}

@media (min-width: 767px) and (max-width: 899.98px) {
    .addAccessManagement.selectDropDown {
        width: 100%;
    }

    .addAccessManagement.selectDropDown.Roles {
        width: 100%;
    }

    .accessDepartment-textWrap {
        width: 100%;
    }

    .addAccessManagement-fieldWrap {
        width: 100%;
    }
}