/* DynamicShimmer.css */
@keyframes shimmerAnimation {
    0% { background-position: -2000px 0; }
    100% { background-position: 2000px 0; }
  }
  
  .datalist-dynamic-shimmer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    max-width: 1855px;
    height: 60px;
    background: #f6f7f8;
    /* border-radius: 8px; */
    animation: shimmerAnimation 1.5s infinite linear forwards;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 50%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 4000px 100%;
    box-sizing: border-box;
  }
  
  .datalist-dynamic-shimmer .datalist-shimmer-block {
    height: 20px;
    background: #e0e0e0;
    /* border-radius: 4px; */
  }
  
  /* Adapt the widths of the blocks as necessary */
  .datalist-dynamic-shimmer .datalist-shimmer-block.short {
      margin: 0 20px;
    width: 7%;
  }
  
  .datalist-dynamic-shimmer .datalist-shimmer-block.medium {
    width: 15%;
      margin: 0 20px;
  }
  
  .datalist-dynamic-shimmer .datalist-shimmer-block.long {
    width: 25%;
      margin: 0 20px;
  }
  
  /* Responsive Design */
  @media (max-width: 1855px) {
    .datalist-dynamic-shimmer {
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .datalist-dynamic-shimmer {
      flex-direction: column;
      height: auto;
    }
  
    .datalist-dynamic-shimmer .datalist-shimmer-block {
      width: 80%;
      margin-bottom: 8px;
    }
  }
  