.machinedashboard-tile {
  width: 96% !important;
  height: 130px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 20px #6d6d6d1a;
  border-radius: 6px;
  margin: 10px; /* Adjust the margin as needed */
  padding: 15px;
  cursor: pointer;
}
.triangle-image-container {
  position: relative;
  display: inline-block;
}

.triangle-image-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  width: 86px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  top: 125%;
  margin-left: -29px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip::after {
  content: "";
  position: absolute;
  bottom: 100%; /* Arrow at the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.triangle-image-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}


.machineoverView-title{
  background: transparent linear-gradient(180deg, #C9CFE7 0%, #E6E9F4 24%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 12px 20px #AAAAAA29; */
  border: 1px solid #E6EAF2;
  border-radius: 8px !important;
  padding: 22px 18px;
  padding: 10px 12px;
  cursor: pointer;
  transition: 0.5s !important;
  width: 96% !important;
}
.input-field-smartTag{
  border: 1px solid black;
  padding: 0px;
  width: 102px;
  height: 40px;
  border-radius: 5px;
  line-height: 30px;
  font-size: 14px;
  outline: none;
  position: relative;
  display: inline-block;
  text-align: center;
}
.input-field-smartTag input {
  width: calc(100% - 40px);
  border: none;
  outline: none;
  text-align: center;
  font-size: 14px;
}
.input-field-smartTag .arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.input-field-smartTag .arrow.left {
  left: 5px;
}
.input-field-smartTag .arrow.right {
  right: 5px;
}
.temperature-description {
  margin-top: -7px;
  font-weight: 400;
}
.triangle-image {
  height: 25px;
}
.updownArrow {
  display: flex;
  padding-top: 36px;
}
.Temprature-box {
  height: 633px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #39393929;
  border: 1px solid #ffffff;
  border-radius: 8px;
}
.machine-temrature-title {
  font-weight: 400;
  margin-left: 7px;
  font-size: 17px;
}
.smartTag-report-btn {
  background: #f0f0fe 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 5px 13px;
  border: none;
  margin-right: 13px;
  font-size: 14px;
  font-weight: 500;
}
.Temrature-line-graph {
  height: 471px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #6d6d6d1a;
  border: 1px solid #a9b0bd;
  border-radius: 6px;
}
.RealTime-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #ebebeb;
  border: 1px solid #a9b0bd;
  border-radius: 6px;
  width: 235px;
  height: 70px;
  color: #1c407b;
}
.box-real-time-title {
  font-size: 14px;
  font-weight: 800;
}
.box-real-time-value {
  float: right;
  margin-top: -13px;
}
.box-value {
  margin-right: 10px;
  font-size: 31px;
}
.Temrature-bar-graph {
  height: 558px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #6d6d6d1a;
  border: 1px solid #a9b0bd;
  border-radius: 6px;
}
.hh-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #7171712e;
  border: 1px solid #a9b0bd;
  border-radius: 6px;
  height: 65px;
  margin-top: 16px;
  width: 174px;
  padding: 10px;
}
.set-range-popup {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #475467;
  width: 17%;
  height: 57px;
  border-radius: 4px;
   
}
.smarttagmachine-dropdown{
  background: #ffffff;
  padding-top:7px !important;
  /* padding-bottom: 7px !important; */
  transform: translate3d(43px, 346px, 0px) !important;
  z-index: 100;
  border-radius: 5px;
}
.smarttagmachine-coc-Paper{
background: none !important;
box-shadow:none !important;
width: 100% !important;
display: flex !important;
justify-content: space-between !important;
align-items: center;
/* margin-top: 5px; */
}
.smarttagmachine-coc-menuItem{
  text-align: left !important;
  font-weight: 500 !important;
}

.Smarttag-search{
  width: 99% !important;
}

@media(min-width:768px) and (max-width:1024px){
  .Temprature-box{
    height: auto !important;
  }
}
@media(min-width:1024px) and (max-width:1199.98px){
  .hh-box{
    width:137px !important;
  }
}