.inpute-week{
    width: 100px;
    height: 36px;
    border: 2px #475467;
}
.typography_Growth{
    font-size: 22px !important;
    font-family: system-ui !important;
    
}
.availability-box{

    box-shadow: 0px 12px 20px #6D6D6D1A !important;
    border-radius: 8px !important;
    padding: 25px 50px !important;
    margin-top: 20px;
}
.BPCLavailability {
    width: 100%;
    height: 70px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 12px #EBEBEB;
    border: 1px solid #A9B0BD;
    border-radius: 6px;
    opacity: 1;
    /* margin-left: 68px; */
    /* margin-top: -31px; */
    margin-top: -25px;
}
.bpclPlant{
    color: #1c407b;
    text-align: left;
    font: normal normal bold 14px / 17px Roboto;
    letter-spacing: 0.1px;
    opacity: 1;
    /* margin-left: 8px; */
    margin-top: 6px;
    font-weight: 400;
    font-size: 14px;
}
h3.plant_time {
    color: #1C407B;
    font-weight: 600;
    font-size: 21px;
}
span.plant_hrs {
    color: #1C407B;
}
button.bpclpeaktime {
    width: 100%;
    height: 88px;
    text-align: left;
    padding-left: 18px;
    background-color: white;
    letter-spacing: 0.42px;
    box-shadow: 0px 6px 12px #EBEBEB;
    border: 1px solid #A9B0BD;
    color: #1C407B;
    border-radius: 6px;

}
.BPCLavailability1 {
    width: 100%;
    height: 80px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 12px #EBEBEB;
    border: 1px solid #A9B0BD;
    border-radius: 6px;
    opacity: 1;
}
.main-bpcl_buttons {
    display: flex !important;
    gap: 53px !important;
}

.BPCLavailability_inverts {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #A9B0BD;
    border-radius: 6px;
    opacity: 1;
    width: 132px;
    height: 66px;
}
.flex-availbility {
    justify-content: space-between;
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
    
    .BPCLavailability {
        width: 100%;
        height: 65px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 6px 12px #EBEBEB;
        border: 1px solid #A9B0BD;
        border-radius: 6px;
        opacity: 1;
        margin-left: 0px;
    }
    button.bpclpeaktime {
        width: 100%;
        text-align: left;
        padding-left: 18px;
        background-color: white;
        letter-spacing: 0.42px;
        box-shadow: 0px 6px 12px #EBEBEB;
        border: 1px solid #A9B0BD;
        color: #1C407B;
        border-radius: 6px;
    }
    .BPCLavailability1 {
        width: 100%;
        height: 91px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 6px 12px #EBEBEB;
        border: 1px solid #A9B0BD;
        border-radius: 6px;
        opacity: 1;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1360px) {
    .BPCLavailability {
        width: 100%;
        height: 70px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 6px 12px #EBEBEB;
        border: 1px solid #A9B0BD;
        border-radius: 6px;
        opacity: 1;
        /* margin-left: 68px; */
        /* margin-top: -31px; */
        margin-top: -2px;
    }
    .Peaktime {
        margin-top: -56px !important;
    }
    
}
@media(min-width: 1024px) and (max-width: 1536px) {
    button.bpclpeaktime{
        width: 100%;
    }
    /* .BPCLavailability {
        width: 168px;
        height: 65px;
        margin-left: 35px;
        margin-top: -31px;
    } */
    /* .BPCLavailability1 {
        width: 123px !important;
        height: 71px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 6px 12px #EBEBEB;
        border: 1px solid #A9B0BD;
        border-radius: 6px;
        opacity: 1;
    } */
    

}
@media (min-width: 1537px) and (max-width: 1920px) {
    /* .BPCLavailability1 {
        width: 126px;
        height: 71px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 6px 12px #EBEBEB;
        border: 1px solid #A9B0BD;
        border-radius: 6px;
        opacity: 1;
    } */
}
