.BPCL_inveters {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #475467 !important;
  width: 116px !important;
  height: 36px !important;
  opacity: 1 !important;
}
.BPCL_invert-1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #475467;
  opacity: 1;
  width: 225px;
  border-radius: 6px;
  height: 36px;
}
.BPCL_palantchart_inverts {
  display: flex;
  gap: 71px;
  margin-left: 28px;
}
.inverts_BPCL {
  display: flex;
  gap: 31px;
}
.inverters-box {
  background-color: white;
  border-radius: 7px;
}
.inverts_BPCL1 {
  display: flex;
  gap: 10px;
}

.inverts_BPCL_0 {
  display: flex;
  gap: 63px;
}
.BPCLavailability_inverts {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a9b0bd;
  border-radius: 6px;
  opacity: 1;
  width: 148px;
  height: 66px;
}
@media (min-width: 1024px) and (max-width: 1536px) {
  .BPCLavailability_inverts {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #a9b0bd;
    border-radius: 6px;
    opacity: 1;
    width: 107px;
    height: 66px;
  }
  .BPCL_palantchart_inverts {
    display: flex;
    gap: 0px;
    margin-left: 28px;
  }
  .inverts_BPCL_0 {
    display: flex;
    gap: 54px;
  }
  .inverter_grid {
    display: flex;
    gap: 39px;
    margin-left: 13px;
  }
}

@media (min-width: 1537px) and (max-width: 1920px) {
  .inverter_grid {
    display: flex;
    gap: 52px;
    margin-left: 15px;
  }
  /* .BPCLavailability1 {
    width: 145px;
    height: 71px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 12px #EBEBEB;
    border: 1px solid #A9B0BD;
    border-radius: 6px;
    opacity: 1;
} */
  span.plant_hrs {
    font-size: 11px;
    margin-top: 9px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1360px) {
  .invertersgenration {
    margin-top: 74px !important;
  }
}

.grid-item-vertical {
  border-right: 1px solid #dfe0e2;
  /* height: auto; */
  padding: 9px;
}
