.reportMangement-reportDetailsWrap {
    display: flex;
    flex-direction: column;
}

.reportMangement-reportDetails-ContenetWrap {
    display: flex;
    align-items: center;
    gap: 5px;
}

.reportmanagement-text {
    margin-bottom: 0px !important;
}

.reportManagement-flex-container {
    display: flex;
    align-items: center;
}

.reportManagement-flex-container div:first-child {
    flex-basis: 14%;
}

.reportManagement-flex-container div:last-child {
    flex-basis: 50%;
}

.reportManagement-flex-container div button {
    margin-right: 4px;
    margin-bottom: 4px;
}

.reportDetailsWrap {
    display: flex;
    align-items: center;
}

.icon-pointer{
    cursor: pointer;
}