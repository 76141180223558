    .dasboardCard {
        /* padding: 30px 0px; */
        /* border: 2px dashed #1C407B; */
        box-shadow: 0px 0px 20px #6D6D6D1A;
        opacity: 1;
        border-radius: 8px !important;
    }

    .dashboardCard-item {
        /* display: flex;
        gap: 20px;
        align-items: center;
        width: 25%;
        justify-content: center;
        cursor: pointer; */
    }

    .dashboardCard-title {
        color: #001323;
        /* font-size: 20px !important; */
        font-size: 18px !important;
        font-weight: 500 !important;
        margin: 0 !important;
    }

    .dashboardCard-number {
        text-align: left;
        color: #001323;
        /* font-size: 40px !important; */
        font-size: 30px !important;
        font-weight: 600 !important;
    }

    .dashboardItem {
        display: flex;
        justify-content: center;
    }

    .Header-TextShimmer {
        margin: 8px auto;
    }

    /* ======================== */

    .dashboardCompany-card {
        background: transparent linear-gradient(180deg, #C9CFE7 0%, #E6E9F4 24%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 12px 20px #AAAAAA29;
        border: 1px solid #E6EAF2;
        border-radius: 8px !important;
        /* padding: 22px 18px; */
        padding: 10px 12px;
        cursor: pointer;
        transition: 0.5s !important;
        height: 163px;

    }

    .dashboardCompany-card:hover {
        box-shadow: 0px 12px 20px #AAAAAAc4 !important;
        transform: translateY(-3px) !important;
    }

    .dashboardCard-plantTitle {
        color: #001323;
        font-size: 24px !important;
        font-weight: 500 !important;
    }

    .dashboardCard-plantsubTitle {
        color: #001323;
        margin-bottom: 20px !important;
    }

    .dashboardCard-companyContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
    }

    .dashboardCard-plantText {
        color: #001323;
        font-size: 20px !important;
        font-weight: 500 !important;
        margin: 0;
    }

    .dashboardCard-plantNumber1 {
        color: #50C878;
        font-size: 30px !important;
        font-weight: 500 !important;
    }

    .dashboardCard-plantNumber2 {
        color: #E31E24;
        font-size: 30px !important;
        font-weight: 500 !important;
        /* border-right: 1px solid black; */
    }

    .dashboardCard-plantSmalltext {
        color: #5473A5;
        margin: 0;
    }

    .dashboardCard-companyImg {
        /* width: 36px; */
        width: 28px !important;
        object-fit: cover;
        margin: 0 auto !important;
    }

    /* @media(min-width:1200px) and (max-width:1500px) {
    .dashboardCard-companyContent {
        gap: 12px !important;
        justify-content: inherit !important;
    }
    .dashboardCard-companyImg {
        width: 26px;
    }
    .dashboardCard-plantText {
        font-size: 12px !important;
    }
    .dashboardCard-plantNumber1 {
        font-size: 16px !important;
    }
    .dashboardCard-plantNumber2 {
        font-size: 16px !important;
    }
    .dashboardCard-plantSmalltext {
        font-size: 10px !important;
    }
} */

    @media(min-width:1400px) and (max-width:1900px) {
        .dashboardCard-companyImg {
            width: 24px;
            /* width: 24px; */
        }

        .dashboardCard-title {
            font-size: 20px !important;
        }

        .dashboardCard-number {
            font-size: 40px !important;
        }
    }

    @media(min-width:1199.98px) and(max-width:1400px) {
        .dashboardCompany-card {
            padding: 0px !important;
        }

        .dashboardCard-companyImg {
            width: 24px !important;
        }
    }

    @media(min-width:899.98px) and (max-width:1024px) {
        .dashboardCompany-card {
            padding: 14px 0px 14px 22px !important;
        }

        .dashboardCard-title {
            font-size: 18px !important;
        }

        .dashboardCard-number {
            font-size: 25px !important;
            text-align: center;
        }

        /* ======================= */
        .dashboardCard-companyContent {
            /* width: 150%; */
            gap: 20px !important;
            /* justify-content: inherit !important; */
        }

        .dashboardCard-companyImg {
            /* width: 26px; */
            width: 20px;
        }

        .dashboardCard-plantText {
            font-size: 12px !important;
        }

        .dashboardCard-plantNumber1 {
            font-size: 16px !important;
        }

        .dashboardCard-plantNumber2 {
            font-size: 16px !important;
        }

        .dashboardCard-plantSmalltext {
            font-size: 10px !important;
        }
        .dashboardCard-companyContent{
            align-items: baseline;
        }
    }

    @media (min-width: 767px) and (max-width: 899.98px) {
        .dashboardCard-img {
            width: 42px;
        }

        .dashboardCard-title {
            font-size: 14px !important;
        }

        .dashboardCard-number {
            font-size: 20px !important;
            text-align: center;
        }

        .dashboardCard-item {
            gap: 14px;
        }

        /* ============================================= */
        .dashboardCard-plantSmalltext {
            font-size: 10px !important;
        }
    }

    @media(max-width:576px) {
        .dashboardCard-companyImg {
            width: 22px !important;
        }
    }
      