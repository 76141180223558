.compactmachinetile {
  width: 204px;
  height: 83px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 20px #6d6d6d1a;
  border-radius: 6px;
  cursor: pointer;
}
.compactview-box {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.smartTagTile {
  width: 204px;
  height: 64px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 20px #6d6d6d1a;
  border-radius: 6px;
}
.TagGroupfield {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 8px 15px;
  border-radius: 6px;
  letter-spacing: 0px;
  color: #001323;
  font-size: 16px;
}
.TagGroupfieldborder {
  border-radius: 6px;
  letter-spacing: 0px;
  color: #001323;
  font-size: 16px;
  border: 1px solid #aebcd2;
  margin-bottom: 10px;
}
.compact-detail-box {
  height: 110px;
  width: 236px;
}
.detailviewgroup-tile {
  /* height: 120px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 20px #6d6d6d1a;
  border-radius: 6px;
  padding-bottom: 0 !important;
  padding: 15px;
  cursor: pointer;
  width: 244px;
}
.licensestatus-active {
  color: #50c878;
}
.licensestatus-inactive {
  color: #e31e24;
}
.alarm-High {
  color: #e31e24;
}
.alarm-Medium {
  color: #ffa500;
}
.alarm-Low {
  color: #ebeb27;
}
.switch-toggle {
  margin-top: -8px;
  margin-right: -10px;
}
