.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    /* height: 100vh; */
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.uploadCSV {
    float: right;
    cursor: pointer;
    margin: 20px 0px 20px 0px;
}

.upload-file-icon {
    margin-top: -2px;
}

.upload-data-card {
    /* z-index: 1100;
    /* position: relative; */
    /* padding: 20px;
    background-color: white;
    border-radius: 8px;
    position: absolute;
    top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
}

.upload-data-card{
    width: auto;
    padding: 12px 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 26px #888888 !important;
    z-index: 99;
}



.upload-data-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #0000001a;
    padding-bottom: 25px;
}

.upload-data-dropdown-wrap {
    width: 50%;
}

.upload-data-dropdown {
    width: 100%;
}

.upload-data-input-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.upload-data-submit-btn {
    display: flex;
    margin: 0 auto;
    background-color: #1c407b;
    border: 0;
    color: #fff;
    padding: 7px 45px 7px 45px;
    letter-spacing: 0.5px;
    border-radius: 4px;
}

.upload-data-download-btn {
    display: flex;
    margin: 0 auto;
    background-color: #1c407b;
    border: 0;
    color: #fff;
    padding: 8px 45px 8px 45px;
    letter-spacing: 0.5px;
    border-radius: 4px;
}

.select-machine-text {
    color: #1c407b;
    font-size: 16px;
    letter-spacing: 0.2px;
    font-weight: 600;
}

.upload-data-text {
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    margin: 0px 0px 40px 0px;
    color: #1c407b;
}

.upload-data-bottom-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 20px 0px 12px 0px;
}

.upload-data-bottom-text h6 {
    color: #1c407b;
    letter-spacing: 0.2px;
    margin: 0;
}

.upload-data-bottom-text h4 {
    color: #1c407b;
    font-size: 16px;
    letter-spacing: 0.2px;
    font-weight: 600;
    margin: 0;
}

.select-pro-dropdown ul {
    height: 300px;
}

.confirmation-modal {
    height: auto;
    width: 400px;
    padding: 25px 10px;
}

.delete-icon {
    cursor: pointer;
}

.upload-data-text-wrap {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.upload-modal-close-icon {
    float: right;
    width: 28px;
    cursor: pointer;
}

.MuiModal-root:focus-visible {
    outline: none !important;
}

.MuiContainer-root.MuiContainer-maxWidthSm {
    outline: none !important;
}

.selected-uid-list {
    font-size: 14px;
    letter-spacing: 0.5px;
}

.selected-uid-list ul {
    padding-left: 0px;
    margin: 0;
}

@media(max-width:576px) {
    .upload-data-wrap {
        flex-direction: column;
    }

    .upload-data-dropdown-wrap {
        width: 100%;
    }

    .upload-data-input-wrap {
        flex-direction: row;
        margin-top: 20px;
    }

    .upload-data-bottom-text h4 {
        text-align: center;
    }
}

@media(max-width:400px) {
    .upload-data-text {
        font-size: 25px;
        margin: 20px 0px 35px 0px;
    }

    .upload-data-input-wrap {
        flex-direction: column;
    }

}