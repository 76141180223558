/* .Grid-text {
  padding: 6px 13px;
  background-color: #efeffd;
} */
.Title-desc {
  font-size: 14px !important;
}

.device-units-name {
  max-height: 54px;
  overflow: auto;
}

.source-box {
  background-color: #fff;
  min-height: 100%;
  border-radius: 6px;
  padding: 10px;
}

.source-table td {
  padding: 10px !important;
  border: none !important;
  border-right: 1px solid #cbc3c3 !important;
  font-size: 16px;
  font-weight: 700;
}

.source-table td:last-child {
  border-right: none !important;
}

.source-table th:last-child {
  border-right: none !important;
}

.source-table th {
  border: none !important;
  border-right: 1px solid #cbc3c3 !important;
  background-color: #f5f5f5;
  font-size: 15px;
  font-weight: 600;
}

.source-table tr {
  border: none !important;
}

.source-table {
  border: none !important;
  border-radius: 0 !important;
}

.source-table tr:nth-child(even) {
  background-color: #f5f5f5;
}

.source-table-box {
  background-color: #fff;
  min-height: 620px;
  border-radius: 6px;
}

.source-boxes {
  padding: 10px;
  background: #f5f5f5;
  font-size: 14px;
  margin-left: 10px;
  float: right;
}

.source-boxes h6 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
}

.time-desc {
  margin-left: 20px;
  margin-right: 10px;
  margin-top: 7px;
  font-size: 16px;
  font-weight: 400;
}

.cardTitle {
  text-align: center;
  font-size: 16px;
  color: #001323;
  background-color: #cad8c5;
  padding: 10px;
  font-weight: 700;
}

.cardDesc {
  text-align: center;
  font-size: 14px;
  color: #001323;
  margin-bottom: 10px;
}

.furnace-grid {
  height: 173px;
  overflow: auto;
}

.device-unit {
  max-height: 224px;
  overflow: auto;
}
.my-slider-class {
  display: block !important; /* Ensure the slider is always displayed */
}


.my-slider-class .slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.my-slider-class .slick-prev {
  left: 14px !important;
}

.my-slider-class .slick-next {
  right: 22px !important;
}

.my-slider-class .slick-prev:before,
.my-slider-class .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #001323;
}

.my-slider-class .slick-next:before,
.my-slider-class .slick-prev:before {
  font-size: 30px;
}

.card-slider-table {
  border: none !important;
  /* width: 50% !important; */
}

.card-slider-table tr {
  border: none !important;
}

.card-slider-table td {
  border: none !important;
  padding: 5px;
}

.card-slider-table tr,
td {
  padding-left: 20px !important
}

.consumption-grid-card {
  display: flex;
  gap: 10px;
}

.average-toggle {
  margin-top: 6px;
  margin-bottom: 0 !important;
}

.average-toggle-switch {
  margin-right: -7px !important;
}

@media (min-width: 767px) and (max-width: 899.98px) {
  .consumption-grid-card {
    display: block !important;
  }

  .card-slider-table tr,
  td {
    padding-left: 5px !important
  }

  .consumption-table-box {
    margin-top: 10px !important;
  }

  .productionAvailibility-box {
    margin-bottom: 15px;
  }
}

@media(min-width: 899.98px) and (max-width: 1024px) {
  .source-boxes {
    padding: 10px;
    background: #f5f5f5;
    font-size: 14px;
    margin-left: 10px;
    float: right;
    width: 116px;
  }
}

@media(max-width:576px) {
  .sticky-page {
    position: sticky;
    margin-bottom: 25px;
  }

  .confi-consumption {
    height: 500px;
    overflow: scroll;
  }
}