.Header-TextShimmer {
  margin: 8px auto;
}

.location-plants-data {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
}
.dashboardlocation-plantTitle {
  color: #001323;
  font-size: 18px !important;
  font-weight: 500 !important;
}
.dashboardlocation-plantsubTitle {
    color: #001323;
    margin-bottom: 10px !important;
    font-size: 12px !important;
}
.dashboardCard-item-location {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 22px;
  cursor: pointer;
  margin: 25px 25px;
  position: relative;
}
.dashboardlocation-plantNumber1 {
  color: #50c878;
  font-size: 16px !important;
  font-weight: 800 !important;
}
.dashboardlocation-plantNumber2 {
  color: #e31e24;
  font-size: 16px !important;
  font-weight: 800 !important;
}