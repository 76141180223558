body {
    font-family: 'Roboto', sans-serif;
}

.main-container {
    min-height: 100vh;
    background-color: #fff;
}


/* ========== SignIn-form-css start ========== */

.signIn-form-wrap {
    padding: 70px 40px 15px 40px;
    position: relative;
}

.signIn-logo {
    margin: 0 auto;
    display: flex;
    height: 77px;
}

.viewIcon {
    position: absolute;
    top: 47.80%;
    right: 8%;
}

.SingIn-unlock {
    /* padding: 15px 0; */
    padding: 15px 0px 111px 0px;
    /* gap: 10px; */
    gap: 4px;
    font-weight: 500;
}

.signIn-footer {
    gap: 20px;
    /* padding: 30px 0px 40px 0px; */
    padding: 0px 0px 0px 0px;
}

.signIn-footer h6 {
    font-weight: 400;
}

.signIn-footer-lines h6 {
    font-weight: 400;
}

.signIn-footer-lines span {
    font-weight: 600;
}
.newPassword{
    font-size: 28px;
}



/* ========== SignIn-form-css end ========== */
@media(min-width:1024px) and (max-width:1400px) {
    .newPassword {
        font-size: 28px !important;
    }
}

@media(min-width:1025px) and (max-width:1170px) {
    .signIn-Footer {
        gap: 2px !important;
    }
}

@media(min-width:899.98px) and (max-width:1024px) {
    .signIn-form-wrap {
        padding: 30px 40px 5px 40px !important;
    }
    .signIn-Footer {
        flex-direction: column;
        gap: 0px !important;
        /* padding: 0px 0px 40px 0px !important; */
        bottom: 95px;
    }
    .newPassword {
        font-size: 24px;
    }
    .SingIn-unlock {
        margin-bottom: 14px;
    }
    .signIn-footer {
        /* gap: 65px !important; */
        /* padding: 0px 0px 40px 0px !important; */
        padding: 0px 0px 0px 0px !important;
    }
}

@media (min-width: 767px) and (max-width: 899.98px) {
    .signIn-form-wrap {
        /* padding: 70px 40px 175px 40px !important; */
        padding: 70px 40px 15px 40px !important;
    }
    .signIn-footer {
        gap: 65px !important;
        /* padding: 0px 0px 40px 0px !important; */
        padding: 0px 0px 0px 0px !important;
    }
}