/* ========== Sign slider-css start ========== */

.slider-container {
    height: 100%;
    overflow: hidden;
}

.slick-slider,
.slick-list,
.slick-track,
.slick-slide>div,
.single-slide {
    /* height: 100vh; */
    height: 100%;
}

.single-slide {
    position: relative;
}

.single-slide img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.slider-detail {
    position: absolute;
    bottom: 100px;
    color: #fff;
    width: 60%;
    left: -50px;
    text-align: left;
    transition: 1s;
    opacity: 0;
    z-index: 1;
}

.slick-dots {
    bottom: 60px;
    width: auto;
    left: 20px;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li button:before {
    font-size: 14px;
    opacity: .50;
    color: #fff;
}

.slick-dots li.slick-active button:before {
    color: #e31e24;
    opacity: 1;
}

.slider-detail h3 {
    /* font-size: 2.5rem; */
    color: #fff;
    /* font-weight: 400; */
    font-weight: normal;
}

.slider-detail p {
    /* font-size: 1.2rem; */
    font-size: 16px;
}

.slick-slide.slick-active .slider-detail {
    left: 25px;
    opacity: 1;
}

.single-slide .overlaySlider {
    background: linear-gradient(rgba(255, 255, 255, 0), #000);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 475px;
    z-index: 0;
}

@media (max-width: 1399.98px) {
    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide>div,
    .single-slide {
        height: 100% !important;
    }
}

@media (min-width: 767px) and (max-width: 899.98px) {
    .slider-wrapper {
        display: none;
    }
}

@media (max-width:899.98px){
    .slider-wrapper{
        display: none;
    }
    .signIn-Footer {
        display: flex;
        flex-wrap: wrap;
        gap: 7px !important;
    }
}
@media(max-width:576px){
    .footer-content {
        position: relative;
        bottom: 0;
        text-align: left;
        font-size: 11px;
    }
    .singIn-title {
        padding-top: 0px;
    }
    .signIn-form-wrap {
        padding: 50px 40px 15px 40px !important;
        position: relative;
    }
    .SingIn-unlock{
        padding: 15px 0px 30px 0px !important; 
    }
    .forgotPassword {
        font-size: 26px !important;
    padding-top: 31px !important;
    }
    .forgotPassword-lines h5 {
        font-size: 14px !important;
    }
    .OTP input {
        height: 43px !important;
        width: 34px !important;
    }
}

/* ========== Sign slider-css end ========== */