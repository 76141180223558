@keyframes shimmerAnimation {
    0% { background-position: -1868px 0; }
    100% { background-position: 1868px 0; }
  }
  
  .status-bar-shimmer {
    width: 1868px;
    height: 91px;
    margin-top: 13px;
    /* margin: 0 auto; */
    background: #ffffff;
    animation: shimmerAnimation 2s infinite linear forwards;
    background-image: linear-gradient(to right, #f0f0f0 0%, #e0e0e0 50%, #f0f0f0 100%);
    background-repeat: no-repeat;
    background-size: 3736px 100%;
      border-radius:12px;
  }
  
  .status-section {
    display: inline-block;
    width: calc(25% - 4px); /* Adjusted to account for potential border or padding */
    height: 100%;
    text-align: center;
  }
  
  .status-icon-placeholder {
    width: 24px;
    height: 24px;
    background: #e0e0e0;
    border-radius: 50%;
    margin: 10px auto;
  }
  
  .status-text-placeholder {
    display: block;
    width: 80%;
    height: 12px;
    background: #e0e0e0;
    border-radius: 4px;
    margin: 8px auto;
  }
  
  .status-number-placeholder {
    display: block;
    width: 40%;
    height: 20px;
    background: #e0e0e0;
    border-radius: 4px;
    margin: 10px auto;
  }
  