.titleWrap {
  display: flex;
  align-items: center;
}

.containerWrap {
  margin: 30px 0px 50px 0px;
}

.top-alert::after {
  content: "";
  height: 19px;
  width: 19px;
  position: absolute;
  background-color: #fff;
  top: -10px;
  left: 45%;
  border-top: #d9dde5 solid 1px;
  border-left: #d9dde5 solid 1px;
  transform: rotate(45deg);
}
.sup-header{
  margin: 3px auto !important;
}
.hiddenImage {
  display: none;
  /* Hide the image initially */
}

.alert-image-data {
  position: absolute;
  right: -78px;
  top: 39%;
  z-index: 999;
  /* width: 75px; */
  /* min-height: 70px; */
  padding: 20px;
  background: white;
  border: 1px solid #d9dde5;
  border-radius: 5px;
  overflow: visible !important;
  box-shadow: 0px 12px 30px #a29e9ec4;
}

.alert-user-image-data::after {
  left: 84% !important;
}

.alert-user-image-data {
  right: 0px !important;
}

.detailsImage {
  position: absolute;
  top: 10px;
  right: 8px;
}

.dashboardCard-item {
  /* display: flex;
  gap: 20px;
  align-items: center;
  width: 25%;
  justify-content: center;
  cursor: pointer; */
}

.superAdmin-dashboardCard-title {
  color: #001323;
  /* font-size: 20px !important; */
  font-size: 16px !important;
  font-weight: 500 !important;
  margin: 0 !important;
  text-align: left !important;
}

.alert-image-data.show {
  opacity: 1;
  transform: translateX(0%) translateY(-50%);
}

.suparAdmin-dashboardCard-number {
  text-align: left;
  color: #001323;
  font-size: 40px !important;
  font-weight: 600 !important;
}

.dashboardItem {
  display: flex;
  justify-content: center;
}

/* ======================== */

.superAdminDashboard-card {
  padding: 34px 60px 34px 60px;
  border-radius: 8px !important;
  position: relative;
  cursor: pointer;
  transition: 0.5s !important;
  overflow: visible !important;
}

.superAdminDashboard-card:hover {
  box-shadow: 0px 12px 20px #aaaaaac4 !important;
  /* transform: translateY(-3px) !important; */
}

.superAdminDashboard-title {
  color: #001323;
  font-weight: 500 !important;
  /* font-size: 35px !important; */
  font-size: 25px !important;
}

.superAdmin-dashboardCard-image {
  height: 70px;
}

.alertImage {
  position: absolute;
  top: 8px;
  right: 12px;
}

.detailsIcon {
  position: absolute;
  top: 10px;
  right: 11px;
}

.superAdminDashboardcard-title {
  color: #001323;
  font-weight: 500 !important;
  /* font-size: 35px !important; */
}

.superAdmin-dashboardCard-item {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 25%;
  justify-content: center;
  cursor: pointer;
}

.superAdmin {
  border: 0;
}

.superAdminDashboard-text {
  color: #001323;
  font-size: 20px !important;
  font-weight: 500 !important;
  margin: 0 !important;
}

.clientManagement-image {
  height: 60px !important;
}

@media (min-width: 1534px) and (max-width: 1825px) {
  .superAdminDashboard-card {
    /* padding: 42px 20px 20px 20px; */
    /* padding: 28px 20px 20px 20px; */
  }
}

/* @media(min-width:1200px) and (max-width:1600px) {
        .superAdminDashboard-card {
            padding: 34px 60px 34px 58px;
        }
        .superAdmin-dashboardCard-title {
            font-size: 20px !important;
        }
    } */

@media (min-width: 1025px) and (max-width: 1640px) {

  /* .superAdminDashboard-card {
            padding: 34px 60px 34px 58px;
        } */
  /* .superAdmin-dashboardCard-img {
            width: 50px;
        } */
  .superAdmin-dashboardCard-title {
    /* font-size: 13px !important; */
    /* font-size: 16px !important; */
    /* font-size: 28px !important; */
  }

  /* .suparAdmin-dashboardCard-number {
            font-size: 34px;
            text-align: center;
        }
        .superAdmin-dashboardCard-item .item {
            flex-direction: column !important;
            gap: 10px;
        }
        .superAdminDashboardcard-title {
            font-size: 20px !important;
        }
        .superAdminDashboard-title {
            font-size: 28px !important;
        }
        .clientManagement-image {
            width: 50px;
        } */
}

@media (min-width: 1199.98px) and (max-width: 1400px) {
  .dasboardCard {
    padding: 8px 0px !important;
  }

  .superAdmin-dashboardCard-title {
    font-size: 14px !important;
  }

  .suparAdmin-dashboardCard-number {
    font-size: 36px !important;
  }

  .superAdminDashboard-card {
    /* padding: 34px 0px 34px 24px; */
    padding: 34px 0px 34px 14px;
  }

  .superAdmin-dashboardCard-image {
    height: 60px;
  }

  .clientManagement-image {
    height: 60px !important;
  }

  .superAdminDashboardcard-title {
    font-size: 22px !important;
  }

  .alert-image-data {
    right: 3px !important;
  }

  .alert-top-data::after {
    left: 85% !important;
  }
}

@media (min-width: 899.98px) and (max-width: 1024px) {
  .suparAdmin-dashboardCard-number {
    text-align: center;
  }

  .superAdmin-dashboardCard-item .item {
    flex-direction: column !important;
    gap: 10px;
  }

  .superAdminDashboardcard-title {
    font-size: 20px !important;
  }

  .superAdminDashboard-title {
    font-size: 20px !important;
  }

  .clientManagement-image {
    width: 50px;
  }

  .alert-image-data {
    right: 3px !important;
  }

  .alert-top-data::after {
    left: 85% !important;
  }
}

@media (min-width: 767px) and (max-width: 899.98px) {

  .suparAdmin-dashboardCard-number {
    text-align: center;
  }

  .superAdmin-dashboardCard-item .item {
    flex-direction: column !important;
    gap: 10px;
  }

  .superAdminDashboardcard-title {
    font-size: 20px !important;
  }

  .superAdminDashboard-title {
    font-size: 30px !important;
  }

  .clientManagement-image {
    width: 50px;
  }

  .alert-image-data {
    right: 3px !important;
  }

  .alert-top-data::after {
    left: 85% !important;
  }
}