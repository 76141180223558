
@keyframes shimmerAnimation {
    0% { background-position: -468px 0; }
    100% { background-position: 468px 0; }
  }
  .shimer-card{
    box-shadow: 0px 12px 20px #AAAAAA29;
    border: 1px solid #E6EAF2;
    border-radius: 8px !important;
    /* padding: 22px 18px; */
    /* padding: 10px 18px; */
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    transition: 0.5s !important;
    width: 96%;
  }
  .shimmer-wrapper{
	display:flex;
    justify-content:space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-left:25px;
}

  .ss-shimmer-card {
    background: #ffffff;
    border-radius: 8px;
    margin: .5rem;
    padding: 15px 7px 15px 7px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    animation: shimmerAnimation 1.5s infinite linear forwards;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 1000px 100%;
    box-sizing: border-box;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
  }
  
  .ss-shimmer-card .shimmer-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  
  .ss-shimmer-card  .shimmer-block {
    background: #e0e0e0;
    border-radius: 4px;
  }
  
  .ss-shimmer-card  .shimmer-header {
    width: 80%;
    height: 20px;
    margin-bottom: 10px;
  }
  
  .ss-shimmer-card  .shimmer-count {
    width: 50%;
    height: 20px;
    margin-bottom: 10px;
  }
  
  .shimmer-icon-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .shimmer-icon {
    background: #e0e0e0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  
  .shimmer-icon-label {
    background: #e0e0e0;
    padding: 0px 26px;
    height: 10px;
    border-radius: 4px;
  }
  
  @media(min-width : 1024px) and (max-width : 1536px){
    .shimmer-icon {
      background: #e0e0e0;
      border-radius: 50%;
      width: 27px;
      height: 29px;
      margin: 0 26px;
  }
  }
  @media(min-width : 767px) and (max-width : 1024px){
    .shimmer-icon {
      background: #e0e0e0;
      border-radius: 50%;
      width: 27px;
      height: 29px;
      margin: 0 26px;
  }
  }