.accountSecurity-card {
    /* height: auto; */
    height: 525px;
    width: auto;
    padding: 12px 20px;
    /* position: absolute; */
    position: fixed;
    /* top: 40%; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 26px #888888 !important;
    z-index: 99;
}

.accountSecurity-cardButtons {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.accountSecurity-title {
    padding-bottom: 20px;
}

.cancelButton {
    background-color: #F4F7FE;
    color: #001323;
    border: 1px solid #1E3760;
    border-radius: 6px;
    width: 200px;
    padding: 12px 48px;
    letter-spacing: 1.8px;
    text-transform: capitalize;
}

.nextButtons {
    background-color: #1C407B;
    color: #fff;
    border: 1px solid #1E3760;
    border-radius: 6px;
    width: 200px;
    padding: 12px 48px;
    letter-spacing: 1.8px;
    text-transform: capitalize;
}
.customeTextField.securityTab{
    padding: 4px 0px 0px 0px;
}

.mobileNumber-card {
    /* height: 350px; */
    width: auto;
    /* padding: 12px 20px; */
    padding: 20px 20px 30px 20px;
    /* position: absolute; */
    position: fixed;
    /* top: 33%; */
    /* left: 66%; */
    /* top: 40%; */
    top:14%;
    left: 55%;
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    /* transform: translate(-9% , 78%); */
    /* box-shadow: 0px 4px 26px #888888!important; */
    box-shadow: 0px 4px 26px #00000040  !important;
}
.mfaText{
    padding: 20px 0px 30px 0px;
    font-size: 14px !important;
}
.mfaCountrypickerwrap{
    width: 100%;
    display: flex;
    align-items: center;
}

.mobileTextField {
    width: 100%;
}
.customeTextField.personal.mfa{
    width: 100%;
}

.otpButton {
    background-color: #1C407B;
    color: #fff;
    border: 1px solid #1E3760;
    border-radius: 6px;
    width: 200px;
    padding: 12px 60px;
    letter-spacing: 1.8px;
    text-transform: capitalize;
    margin: 0 auto;
    display: flex;
    margin-top: 40px;
}

.mobileNumber-input input {
    height: 50px !important;
    width: 52px !important;
    /* width: 55px !important; */
    background-color: #F4F7FE;
    border-radius: 4px 4px 0px 0px;
    padding-bottom: 2px;
    border: 0;
    border-bottom: 4px solid #d1d6d6;
    margin: 0 auto;
    /* margin-right: 2px !important; */
    /* display: flex !important; */
    /* gap: 20px !important; */
}

.optCard {
    height: 350px;
    width: auto;
    padding: 20px 20px;
    /* position: absolute; */
    position: fixed;
    /* top: 33%; */
    /* left: 66%; */
    /* top: 40%; */
    top: 14%;
    left: 55%;
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    box-shadow: 0px 4px 26px #888888 !important;
}

.MFA-card {
    height: 500px;
    width: auto;
    /* width: 24%; */
    padding: 12px 20px;
    position: absolute;
    /* position: fixed; */
    /* top: 40%; */
    top: 14%;
    /* left: 49%; */
    left: 56%;
    /* left: 55%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    border: 1px solid #5f5f5f75 !important;
    /* border-radius: 8px !important; */
    box-shadow: 0px 4px 26px #888888 !important;
    /* box-shadow: #6D6D6D1A !important; */
}
.scanCard{
    min-height: 500px;
    width: auto;
    padding: 33px;
    z-index: 99;
    position: absolute;
    /* top: 40%; */
    top: 14%;
    /* left: 49%; */
    left: 56%;
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    border: 1px solid #5f5f5f75 !important ;
    box-shadow: 0px 4px 26px #888888 !important;
}
.logoutModal{
    height: auto;
    width: 400px;
    padding: 20px 20px 20px 20px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #5f5f5f75 !important ;
    box-shadow: 0px 4px 26px #888888 !important;
    z-index: 9999;
}
.logoutModal-text{
    text-align: center;
}
.logoutmodal-btnWrap{
    display: flex;
    justify-content: center;
    gap: 6px;
}
.logoutBtn{
    background-color: #1C407B;
    color: #fff;
    border: 1px solid #1E3760;
    border-radius: 6px;
    width: 100px;
    padding: 12px;
    letter-spacing: 1.8px;
    text-transform: capitalize;
    /* margin: 0 auto; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}
.scanImage{
    width: 200px;
    margin: 0 auto;
    display: flex;
    margin-top: 70px;
    margin-bottom: 70px;
}
.googleAuthenticator{
    height: 150px;
    width: auto;
    padding: 20px 20px 10px 20px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #5f5f5f75 !important ;
    box-shadow: 0px 4px 26px #888888 !important;
}
.googleAuthenticator-title{
    display: none;
}
.googleAuthenticator-text{
    color: #1C407B !important;
    padding-top: 4px;
}


/* .MFA-tetx {
    width: 3200px;
    margin: 0 auto !important;
} */

.loaderImage {
    margin: 0 auto;
    display: flex;
    padding: 40px 0px 10px 0px;
}

.nextButton {
    background-color: #1C407B;
    color: #fff;
    border: 1px solid #1E3760;
    border-radius: 6px;
    width: 205px;
    padding: 12px;
    letter-spacing: 1.8px;
    text-transform: capitalize;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}
.mobileNumber-DropdownWrap.wrap{
    padding: 22px 0px 0px 10px;
}

.closeIcon {
    width: 40px;
}

@media(min-width:1024px) and (max-width:1536px) {
    .accountSecurity-card {
        top: 55%;
    }
}

@media(min-width:899.98px) and (max-width:1024px) {
    .accountSecurity-card {
        width: auto !important;
        height: auto !important;
        z-index: 99;
    }
    .closeIcon {
        width: auto;
    }
    .mobileNumber-card {
        padding: 20px 20px;
        width: auto;
        height: auto;
        z-index: 99;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .optCard {
        width: auto;
        height: auto;
        z-index: 99;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .MFA-card {
        width: auto;
        height: auto;
        z-index: 99;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    /* .MFA-tetx {
        width: 400px;
        margin: 0 auto !important;
    } */
    .mfaModal-closeIcon {
        width: 40px !important;
        height: 30px !important;
    }
    .scanCard{
        width: auto;
        height: auto;
        z-index: 99;
        padding: 20px 20px;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .googleAuthenticator{
        height: auto;
        width: auto;
        z-index: 99;
        padding: 20px 14px 40px 14px;
     }
}

@media (min-width: 767px) and (max-width: 899.98px) {
    .close-password-popup{
        height: 30px;
    }
    .accountSecurity-card {
        padding: 20px 20px;
        /* width: auto; */
        height: auto;
        z-index: 99;
    }
    .closeIcon {
        width: auto;
    }
    .mobileNumber-card {
        padding: 20px 20px;
        width: auto;
        /* width: 60%; */
        height: auto;
        z-index: 99;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .optCard {
        width: auto;
        height: auto;
        z-index: 99;
        /* left: 52%; */
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .MFA-card {
        width: auto;
        height: auto;
        z-index: 99;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .mfaModal-closeIcon {
        width: 40px !important;
        height: 30px !important;
    }
    /* .MFA-tetx {
        width: 300px;
        margin: 0 auto !important;
    } */
    .scanCard{
        width: auto;
        height: auto;
        z-index: 99;
        padding: 25px 36px;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .googleAuthenticator{
       height: auto;
       width: auto;
       z-index: 99;
       padding: 20px 14px 40px 14px;
    }
}

@media (max-width: 768px) {
    .center-div {
        top: 40%;
    }
}

.customeTextField .MuiInput-underline:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.customeTextField .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.87) !important;
}

.customeTextField .MuiInput-underline:after {
    border-bottom: 1px solid #1976d2 !important;
}