.overviewModal-box-closeImg {
  margin-left: 292px !important;
  margin-top: -61px !important;
  position: relative !important;
}

.closeOverviewModal {
  position: absolute;
  right: -20px;
  top: -12px;
  width: 42px !important;
  cursor: pointer;
}
.unity-table-modal {
  padding: 100px 14px;
}
.priority-submit-btn {
  background: #1c407b;
  color: white;
  padding: 5px 23px;
  border-radius: 5px;
  float: inline-end;
  border: none;
}
.realData-show {
  border: 1px solid;
  padding: 5px 10px;
  background: white;
}
