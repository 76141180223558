.smarttag-machine-analytics-main-container {
  display: flex;
  justify-content: space-between;
}
.smartTag-boxes {
  background-color: #fff;
  min-height: 943px;
  border-radius: 6px;
  padding: 10px;
}
.smartTag-report-taglist-unit {
  height: 560px;
  overflow: auto;
}
.smartTag-report-taglist-unit-data {
  height: 273px;
  overflow: auto;
}
.smarTag-Unit-grid {
  height: 330px;
  overflow: auto;
}
.smartTag-boxes-report {
  background-color: #fff;
  height: 665px;
  border-radius: 6px;
  padding: 10px;
}
.smarTag-grid-box {
  background-color: #fff;
  height: 724px;
  border-radius: 6px;
  padding: 10px;
}
