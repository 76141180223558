.Shimmer-DonutChart {
  margin-top: 68px !important;
  margin-bottom: 50px !important;
}

.ParetoChartShimmer-small {
  margin-top: 15px !important;
}

@media (max-width: 599.98px) {
  .graph-type-box {
    height: 58px;
    width: 58px;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-readOnly.MuiInputBase-readOnly.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    height: 39px;
    font-size: 12px;
  }

  .silder--title {
    font-size: 10px;
  }

  .silder--data {
    margin-top: -2px;
    font-size: 20px;
  }

  .silder--unit {
    margin-top: -2px;
    font-size: 12px;
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: transparent;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb:horizontal:hover {
    background-color: transparent;
  }
}

@media(max-width:767px){
  .graph-type-box {
    width: 48px;
    height: 48px;
  }
  .process-header-btn-date input{
    padding: 10px;
    font-size: 12px;
  }
  .process-header-btn-date svg{
    width: 15px;
  }
  .process-header-btn-month input{
    padding: 10px;
    font-size: 12px;
  }
  .process-header-btn-month svg{
    width: 15px;
  }
}

@media(max-width:576px) {
  .graph-type-box {
    width: 45px;
    height: 45px;
  }
  .process-header-btn-date input{
    padding: 10px;
    font-size: 12px;
  }
  .process-header-btn-date svg{
    width: 15px;
  }
  .process-header-btn-month input{
    padding: 10px;
    font-size: 12px;
  }
  .process-header-btn-month svg{
    width: 15px;
  }
}