.custom-grid {
  display: flex;
  justify-content: center;
}

/* .card_weather {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #1c407b;
  border-radius: 6px;
  opacity: 1;
} */
.card_weather {
  padding: 16px; /* Inner spacing for the card */
  background-color: #ffffff;
  border: 1px solid #1c407b;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Box shadow */
  height: 294px;
}

.weather-card-data{
  display: flex; /* Enable flexbox */
  flex-direction: column; /* Align content vertically */
  align-items: center; /* Horizontal center */
  justify-content: center; /* Vertical center */
}
#guage_1 {
  width: 500px;
}
#guage_2 {
  width: 500px;
}
#guage_3 {
  width: 500px;
}
#guage_4 {
  width: 500px;
}
#guage_5 {
  width: 500px;
}
#guage_6 {
  width: 500px;
}
.gauge_container {
  display: flex; /* Enable flexbox */
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  width: 100%; /* Full width */
}

#guage_7 {
  width: 500px;
  transform: translateY(-13px); /* Adjust Y-axis position */
}
#guage_8 {
  width: 500px;
  transform: translateY(-13px); /* Adjust Y-axis position */
}
#guage_9 {
  width: 500px;
  transform: translateY(-13px); /* Adjust Y-axis position */
}
#guage_10 {
  width: 500px;
  transform: translateY(-13px); /* Adjust Y-axis position */
}

.weather_BPCL {
  font-size: 18px;
  /* margin-left: 19px; */
}

.amb_BPCL {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #475467;
  opacity: 1;
  width: 124px;
  height: 37px;
}

.BPCL_weather_chart {
  display: flex;
  justify-content: space-between;
}

.BPCL_buttons {
  display: flex;
}

button.live-genration {
  width: 116px;
  height: 36px;
  border-radius: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #475467;
  background-color: white;
}
.BPCLavailability_weather {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a9b0bd;
  border-radius: 6px;
  opacity: 1;
  width: 150px !important;
  height: 66px;
}
.BPCL_palantchart_weather {
  display: flex;
}

.spacing_BPCL {
  display: flex;
  /* gap: 66px; */
}
.inpute-week {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #475467;
  opacity: 1;
  border-radius: 6px;
  width: 124px;
  height: 36px;
}

@media (min-width: 1024px) and (max-width: 1536px) {
  .BPCLavailability_weather {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #a9b0bd;
    border-radius: 6px;
    opacity: 1;
    width: 100px !important;
    height: 66px;
  }
  .spacing_BPCL {
    display: flex;
    /* gap: 50px; */
  }
  .bpclPlant {
    color: #1c407b;
    text-align: left;
    font: normal normal bold 14px / 17px Roboto;
    letter-spacing: 0.42px;
    color: #1c407b;
    opacity: 1;
    margin-top: 6px;
    font-size: 11px !important;
  }
  #guage_1 {
    width: 500px;
    transform: translateY(-13px);
  }
  #guage_2 {
    width: 500px;
    transform: translateY(-13px);
  }
  #guage_3 {
    width: 500px;
    transform: translateY(-13px);
  }
  #guage_4 {
    width: 500px;
    transform: translateY(-13px);
  }
  #guage_5 {
    width: 500px;
    transform: translateY(-13px);
  }
  #guage_6 {
    width: 500px;
    transform: translateY(-13px);
  }
}

@media only screen and (min-width: 1537px) and (max-width: 1920px) {
  #guage_7 {
    width: 500px;
    transform: translateY(-13px); /* Adjust Y-axis position */
  }
  #guage_8 {
    width: 500px;
    transform: translateY(-13px); /* Adjust Y-axis position */
  }
  #guage_9 {
    width: 500px;
    transform: translateY(-13px); /* Adjust Y-axis position */
  }
  #guage_10 {
    width: 500px;
    transform: translateY(-13px); /* Adjust Y-axis position */
  }
}

.grid-vertical-item {
  position: relative; /* Required for the pseudo-element positioning */
  padding: 23px;
}

.grid-vertical-item::before {
  content: "";
  position: absolute;
  top: 30%;
  bottom: 10%; 
  right: 0; 
  width: 1px; 
  background-color: #dfe0e2; 
}

