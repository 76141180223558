/* StatusBarShimmer.css */
@keyframes shimmerAnimation {
  0% {
    background-position: -1868px 0;
  }
  100% {
    background-position: 1868px 0;
  }
}

.shimmer-card-company {
  padding: 24px 10px 24px 10px !important;
  animation: shimmerAnimation 2s infinite linear forwards;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
}
