.machine-card {
  background-color: white;
  box-shadow: 0px 6px 12px #7e7e7e29;
  border-radius: 8px;
  height: 670px;
}
.selected-priority-btn {
  background: #2eb652 !important;
  border: 1px solid #50c878 !important;
  color: white !important;
  padding: 7px 11px !important;
}
.selected-unhealthy-btn {
  background: #ca1c27 !important;
  color: white !important;
  padding: 7px 10px !important;
}

.unity-modal-btn{
  background: #f0f0fe;
  border: none;
  padding: 9px;
  border-radius: 4px;
}
.check-Icon {
  font-size: 21px;
  margin-right: 7px;
  margin-top: -3px;
}
.priority-submit-btn {
  background-color: #1c407b;
  border-radius: 5px;
  color: white;
  border: 1px solid #1c407b;
  padding: 5px 19px;
  float: inline-end;
}
.dashboard-machine {
  background: transparent
    linear-gradient(180deg, #c9cfe7 0%, #e6e9f4 24%, #ffffff 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 12px 20px #aaaaaa29 !important;
  border: 1px solid #e6eaf2;
  border-radius: 8px !important;
  /* padding: 22px 18px; */
  /* padding: 10px 18px; */
  padding: 15px;
  cursor: pointer;
  transition: 0.5s !important;
}
.machine-dropdown{
  transform: translate(43px,32px);
}
.device-image-modal{
  transform: translate(54px,133px);
}
.submachineTable tr {
  border: none !important;
}
.shimmer-title-line {
  height: 13px !important;
}
.dashboardCard-companyTitle {
  color: #001323;
  font-size: 19px !important;
  font-weight: 500 !important;
}
.notificationModal {
  padding: 20px;
}
.shimmer-style{
  margin-bottom: 0px !important;
}
.search-icon {
  color: #90a3b9;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 24px !important;
  width: 24px !important;
}
.subMachine-device-table td {
  padding: 10px;
  font-size: 12px;
  letter-spacing: 0;
  background-color: #f5f5f5;
}

.subMachine-device-table th {
  padding: 10px;
}

.subMachine-device-table {
  border-collapse: separate !important;
}

.subMachine-device-table thead {
  position: sticky;
  top: 0;
  z-index: 999;
}

.overviewModal-box {
  position: absolute;
  background-color: #fff;
  padding: 10px;
  left: 17.8%;
  top: 22%;
  width: 320px;
  height: 710px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 30px #717171cc;
  border-radius: 6px;
  opacity: 1;
}

/* .submachine-Table,tr,td{
  border: none !important;
}

.submachine-Table tbody{
  border: none !important;
} */
.submachine {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #7e7e7e29;
  border-radius: 8px;
  height: 634px;
  padding: 20px;
}
.submachine-tag {
  box-shadow: 0px 6px 12px #7e7e7e29;
  border: 1px solid #a9b0bd;
  border-radius: 8px;
  height: 634px;
  overflow: auto;
}
.submachine-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  height: 496px;
}
.view-machine {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #1c407b;
  border-radius: 40px;
  width: 210px;
  padding: 10px;
}
.submachineTable {
  border-collapse: separate;
  border-spacing: 0;
}

.submachineTable thead th {
  padding-bottom: 10px; /* Adjust the desired spacing value */
}

.submachineTable tbody td {
  padding-top: 10px; /* Adjust the desired spacing value */
}
.submachineTable thead {
  position: sticky;
  top: 0;
  z-index: 99;
}
.vms-report-taglistUnit{
  height: 516px;
  overflow: auto;
}

.vms-report-taglist-unit{
  height: 364px;
  overflow: auto;
}
/* table.MuiTable-root.submachineTable:first-child {
  border-collapse: separate;
  border-spacing: 0px 0.5em !important;
} */
.average-toggle-switch-vms {
  margin-top: -5px;
  margin-right: 17px;
  }
.average-toggle-vms{
  margin-top: 2px;
  margin-bottom: 0 !important;
}
.submachine-title {
  background: white;
  padding: 12px 20px;
  border-radius: 8px 8px 0px 0px;
}
.submachine-titlebody {
  display: flex;
  /* gap: 63px; */
  /* background: white; */
  padding: 12px 20px;
  border-radius: 8px;
  margin: 0 20px;
}
.submachine-titlebody h6 {
  margin-bottom: 0;
  font-size: 15px;
  letter-spacing: 0px;
  color: #001323;
  font-weight: 400;
}
.machine-btn {
  padding: 17px;
  font-size: 18px;
  width: 100%;
  color: #001323;
  font-weight: 500;
  background: #ffffff;
  border: 1px solid #103168;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  letter-spacing: 0.6px;
  font-weight: 700 !important;
}
.machine-overView {
  height: 712px;
  padding: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #7e7e7e29;
  border-radius: 8px;
}
.machine-overView1 {
  height: 712px;
  padding: 20px 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #7e7e7e29;
  border: 1px solid #a9b0bd;
  border-radius: 8px;
}
.table-width{
  font-weight: 600 !important;
   text-align: center !important;
   width: 20%;
  font-size: 18px !important;
}
.table-body-spacing {
  margin-top: 10px; /* Adjust the desired spacing value */
}
.vms-coc-menuItem{
  justify-content: space-between !important;
}
.vms-overview td {
  padding: 0px !important;
  padding-left: 20px !important;
}
.vms-overview th {
  font-weight: 700;
  padding: 0px !important;
  padding-left: 20px !important;
}
.analytics-dashboard-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #67676747;
  border: 1px solid #103168;
  border-radius: 4px;
  width: 100%;
  padding: 20px;
  color: #001323 !important;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  font-weight: 700;
}
.vms-graph {
  display: flex;
}
.machineanalytics-chartText {
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  margin-top: -4px;
  color: #001323;
  letter-spacing: 0px;
  font-weight: 600 !important;
  margin-bottom: 0;
}
.machine-meterCard {
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 2px;
  padding: 0 20px;
}
.submachineTable td {
  border: none !important;
  padding: 12px !important;
}

.submachineTable tr td {
  border: none !important;
  background-color: white;
}
.submachineTable th {
  letter-spacing: 0px;
  color: #001323;
  font-weight: 500;
}
.submachine-tag1 {
  box-shadow: 0px 6px 12px #7e7e7e29;

  border-radius: 8px;
  height: 640px;
  background-color: white;
}
.overViewMachinetable td {
  border: none !important;
  font-size: 17px;
  padding: 0 !important;
  margin-bottom: 8px;
}
.overViewMachinetable tr {
  border: none !important;
}
.healthy-btn {
  background: #dff5e6 0% 0% no-repeat padding-box;
  border: 1px solid #50c878;
  border-radius: 6px;
  color: #50c878;
  padding: 7px 20px;
  margin-right: 10px;
}
.unhealthy-btn {
  background: #ffe8e9 0% 0% no-repeat padding-box;
  border: 1px solid #e31e24;
  border-radius: 6px;
  padding: 7px 20px;
  color: #e31e24;
}

.machine-msg h6 {
  font-weight: 400;
}
.reason-textfield {
  border: none;
  width: 87%;
  border-bottom: 1px solid #7e7e7e29;
}
.reason-textfield:focus-visible {
  outline: none;
  border: none;
  width: 87%;
  border-bottom: 1px solid #7e7e7e29;
}
.graphtableparams {
  border-left: 4px solid #9a64b2 !important;
}
.graphtableparams1 {
  border-left: 4px solid #ef75a0 !important;
}
.graphtableparams2 {
  border-left: 4px solid #7bcbc1 !important;
}
.graphtableparams3 {
  border-left: 4px solid #c87171 !important;
}
.information-card {
  width: 350px;
  padding: 20px;
  height: 170px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #6464648a;
  border-radius: 8px;
  text-align: center;
  float: right;
  margin-top: 20px;
  position: absolute;
  left: 47%;
}
.information-card h6 {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 20px;
}
.information-card:before {
  content: "";
  display: block;
  position: absolute;
  top: -11%;
  right: 3%;
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 31px solid #fff;
  z-index: 0;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .vms-graph {
    display: block !important;
  }
  .machineimg {
    width: 100%;
  }
  .popup-box {
    width: 65%;
  }
  .machineanalytics-chartText {
    margin-bottom: 19px;
  }
  .vms-analytics-marquee {
    height: 550px !important;
  }
  .table-back-color{
     background-color: "F1F1FF";
     font-size: 13px;
  }
  .device-uid-box{
    max-height: 425px;
    overflow: auto;
  }
  .device-table-tag{
    max-height: 454px;
    overflow: auto;
  }
  .search-table-row1 {
    position: relative;
    padding: 5px 16px 5px 18px !important;
    background: #f1f1ff;
    height: 50px;
    border: 1px solid #a9b0bd;
    border-radius: 4px;
    display: flex;
}
  .information-card {
    width: 350px;
    padding: 20px;
    height: 170px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #6464648a;
    border-radius: 8px;
    text-align: center;
    float: right;
    margin-top: 20px;
    position: absolute;
    left: 50% !important;
  }
  .overviewModal-box {
    position: absolute;
    background-color: #fff;
    padding: 10px;
    left: 54.8%;
    top: 14%;
    width: 320px;
    height: 710px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 12px 30px #717171cc;
    border-radius: 6px;
    opacity: 1;
}
.machine-dropdown{
  width: 39% !important;
}


}
@media (min-width: 1024px) and (max-width: 1536px) {
  .information-card {
    width: 350px;
    padding: 20px;
    height: 170px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #6464648a;
    border-radius: 8px;
    text-align: center;
    float: right;
    margin-top: 20px;
    position: absolute;
    left: 39% !important;
  }
  .vms-analytics-marquee {
    height: 580px !important;
  }
  .overview-source-table th{
    border: none !important;
    border-right: 1px solid #cbc3c3!important;
    background-color: #f5f5f5;
    font-size: 15px;
    padding: 17px 47px;
    font-weight: 600;
  }
  .machine-dropdown{
    width: 22% !important;
  }
}
@media (min-width: 1537px) and (max-width: 1920px){
  .overviewModal-box {
    position: absolute;
    background-color: #fff;
    padding: 10px;
    left: 24.9%;
    top: 15%;
    width: 320px;
    height: 710px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 12px 30px #717171cc;
    border-radius: 6px;
    opacity: 1;
  }
  

  
}

@media (min-width: 1920px) {
  .SubMachine-390 {
    text-align: center;
    WIDTH: 71%;
    margin-left: auto;
    margin-right: auto;
  }
}