.production-data {
  padding: 16px;
  border-radius: 8px;
  background: white;
}

.grayed-checkbox {
  color: #888;
  text-decoration: line-through;
}

.enabled-checkbox {
  color: #1c407b !important;
  margin-top: 8px;
  margin-left: -10px;
}
.ana-popup{
  width: 30% !important;
}

.production-data .single-data-item,
.matching-data-section .single-data-item {
  border-right: 1px solid #a9b0bd;
}

.production-data .single-data-item,
.matching-data-section .single-data-item {
  border-right: 1px solid #a9b0bd;
}

.production-data .single-data-item:last-child,
.matching-data-section .single-data-item:last-child {
  border-right: 0px;
}

.production-data .common-table,
.matching-data-section .common-table {
  width: 90%;
  margin-left: 10px;
}

.production-data .common-table td,
.matching-data-section .common-table td:first-child {
  padding: 8px !important;
  font-size: 18px;
}

.production-data .common-table td:nth-child(2),
.matching-data-section .common-table td:nth-child(2) {
  padding: 8px !important;
}

.production-data-table .common-table td,
.matching-data-section .common-table td:first-child {
  padding: 8px !important;
  font-size: 18px;
}

.production-data-table {
  padding: 20px;
  border-radius: 8px;
  background: white;
  /* width: 30%; */
}

.production-data-table1 {
  padding: 20px;
  border-radius: 8px;
  background: white;
  width: 90%;
}

.common-table td {
  padding: 0px;
}

.p-card h4 {
  font-size: 17px;
  font-weight: 600;
}

.pData {
  font-size: 16px;
  font-weight: 500;
}

.grid-data {
  background-color: red;
  height: 300px;
}

.chart-card {
  background-color: #ffff;
  height: 580px;
}

.analyticsGraph {
  height: 532px;
  transform: translate(-7px, 50px);
}

.clock-hand {
  transform-origin: 50% 100%;
  transition: transform 0.5s;
}

.consumption-slot {
  height: 12px;
  width: 12px;
  background-color: #1c407b;
  border-radius: 50%;
}

.Loss-slot {
  height: 12px;
  width: 12px;
  background-color: #ccd7eb;
  border-radius: 50%;
}

.disabled-checkbox {
  color: gray;
  cursor: not-allowed;
}

.enabled-checkbox {
  color: "#1C407B" !important;
}

.production-btn {
  padding: 25px;
  font-size: 18px;
  width: 100%;
  color: #001323;
  font-weight: 500;
  background: #ffffff;
  border: 1px solid #103168;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  letter-spacing: 0.6px;
  font-weight: 700 !important;
}

.furnace-1 {
  max-height: 356px;
  overflow: auto;
}

.analytics-date-picker {
  height: 50px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #475467;
}

.consumerEnergyData-box {
  background-color: #fff;
  min-height: 681px;
  border-radius: 6px;
  padding: 10px;
}

.overlay {
  position: absolute;
  top: 23px !important;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5) !important;
  /* transparent black */
  display: block;
  /* initially hidden */
  height: 100%;
}

.overlay1 {
  position: absolute;
  top: 0px !important;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5) !important;
  display: block;
  height: 100%;
  cursor: pointer;
  opacity: 0.4;
}

.closeIcon {
  float: inline-end;
  margin: 7px 12px;
  cursor: pointer;
}


.production-box {
  height: 130px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px #0000008a;
  border-radius: 6px;
  opacity: 1;
  margin-top: 20px;
  left: 20%;

  width: 36%;
}

.production-title {
  transform: translate(39px, 50px);
  color: #1c407b;
}

.production-text {
  font-size: 17px;
  font-weight: 600;
}

.production-input-color {
  /* opacity: 1.5 !important; */
  opacity: 0.4;
  cursor: pointer;
  margin-bottom: 11px !important;
  /* background-color: rgba(0, 0, 0, 0.5) !important; */
  padding: 15px;
  border: none;
  border-radius: 4px;
  /* opacity: 0; */
  width: 90% !important;
  color: black;
  font-weight: 600;
  pointer-events: none;
}

.availibilty-title {
  width: 50%;
  text-align: left;
}

.consumerData-boxes {
  background-color: #fff;
  min-height: 681px;
  border-radius: 6px;
  padding: 10px;
}

a:hover {
  color: #001323;
}

.page-header-analytics {
  display: flex;
  justify-content: space-between;
}

.prod-table {
  background-color: #aebcd2b3 !important;
  border: 2px dashed #1c407b;
  opacity: 0.8;
  border-right: 2px dashed #1c407b !important;
}

.production-tablecell {
  background-color: #aebcd2b3 !important;
}

.Analytics-deviceModal-popup {
  /* width: 472px;
  height: 636px;
  background: #ffffff;
  box-shadow: 0px 12px 30px #6e6e6e75;
  border-radius: 6px;
  position: fixed;
  top: 17%;
  left: 38%; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.confi-analytics-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.chart-card-opacity {
  background: #aebcd2b3 !important;
  opacity: 0.8;
  border: 2px dashed #1c407b;
}

.form-input-production-pairs {
  margin-bottom: 11px !important;
  background-color: #f1f1ff;
  padding: 15px;
  border: none;
  border-radius: 4px;
  opacity: 1;
  width: 90% !important;
}

.form-input-production-pairs-hours {
  margin-bottom: 11px !important;
  background-color: #f1f1ff;
  padding: 15px;
  border: none;
  border-radius: 4px;
  opacity: 1;
 
}

.form-input-production-footprint {
  margin-bottom: 11px !important;
  background-color: #f1f1ff;
  padding: 15px;
  border: none;
  border-radius: 4px;
  opacity: 1;
  width: 90% !important;
}

.input-label {
  position: absolute;
  right: 5%;
  top: 16px;
  font-weight: 500;
}

.input-label-kt {
  position: absolute;
  /* right: 14%; */
  margin-left: -28px;
  top: 84px;
  font-weight: 500;
}

.input-label-rupees {
  position: absolute;
  right: 15%;
  top: 16px;
  font-weight: 500;
}

.input-label-ktt {
  position: absolute;
  right: 14%;
  top: 16px;
  font-weight: 500;
}

.popup-open {
  pointer-events: none;
}


.slotBox {
  min-height: 200px;
  background: #f1f1ff;
  min-width: 600px;
}

.slotBox-data {
  padding: 25px 12px 25px 12px;
  height: 330px;
  overflow: auto;
}

.analytics-cancle-btn {
  background: #ffffff;
  border: 1px solid #1e3760;
  border-radius: 4px;
  padding: 11px 55px;
}

.analytics-submit-btn {
  background: #1c407b;
  border: 1px solid #1e3760;
  border-radius: 4px;
  padding: 11px 55px;
  color: #ffff;
}

.analytics-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.addbtn {
  background: #ffffff;
  border: 1px solid #1e3760;
  border-radius: 4px;
  padding: 4px 17px;
  font-size: 14px;
}

div.consumer-text:nth-child(1) {
  border-left: 4px solid #9a64b2;
}

div.consumer-text:nth-child(2) {
  border-left: 4px solid #ef75a0;
}

div.consumer-text:nth-child(3) {
  border-left: 4px solid #ef75a0;
}

div.consumer-text:nth-child(4) {
  border-left: 4px solid #ef75a0;
}

div.consumer-text:nth-child(5) {
  border-left: 4px solid #ef75a0;
}

div.consumer-text:nth-child(6) {
  border-left: 4px solid #ef75a0;
}

div.consumer-text:nth-child(7) {
  border-left: 4px solid #ef75a0;
}

div.consumer-text:nth-child(8) {
  border-left: 4px solid #ef75a0;
}

div.consumer-text:nth-child(9) {
  border-left: 4px solid #ef75a0;
}

.header-btn-date {
  margin-right: 13px;
  width: 31%;
}

/* .header-btn-month {
  width: 31%;
} */
.addbtn-disable {
  background: #ffffff;
  border: 1px solid #1e3760;
  border-radius: 4px;
  opacity: 0.5;
}

.grid-container {
  flex-wrap: initial !important;
  gap: 10px;
}

.editPopupContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  /* z-index: 9999; */
}

.editCard {
  padding: 20px;
}

.editData {
  width: 90%;
  max-width: fit-content;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 0px 30px #0000008a;
}

.cancel-icon {
  color: #B80606;
}

.editdata-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slotBox-wrap {
  display: flex;
  align-items: center;
  gap: 20px;
}

.slotbox-timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.slotbox-timer h6 {
  text-align: center;
  margin: 0;
}

.slotbox-input {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #475467;
  width: 65px;
  height: 58px;
  font-size: 14px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  /* .page-header-analytics {
    display: block !important;
  } */
  .chart-card {
    height: 1030px;
  }

  /* .analysis-tabs-btn {
    display: block !important;
  } */

  /* .editCard {
    width: 638px !important;
  } */

  /* .editData {
    left: 16%;
  } */

  /* .analytics-datepicker {
    margin-top: 20px;
    margin-left: 480px;
  } */
  .consumerData-box {
    margin-bottom: 15px;
  }

  /* .header-btn-month {
    width: 22% !important;
  } */
  .production-coc-datepicker {
    width: 30% !important;
  }

  .header-btn-date {
    width: 22% !important;
  }

  .production-data-table1 {
    width: 100%;
  }

  .dispatch-data {
    gap: 20px;
    /* flex-wrap: wrap; */
  }

  .form-input-production-pairs {
    width: 88%;
  }

  .form-input-production {
    width: calc(100% - 20px) !important;
  }

  .form-input-production-footprint {
    width: calc(100% - 20px) !important;
  }

  .pData2 {
    margin-left: -23px !important;
  }

  .pData1 {
    margin-left: -26px !important;
  }

  .pData0 {
    margin-left: -13px !important;
  }

  .grid-container {
    flex-wrap: wrap !important;
    gap: 0px !important;
  }

  /* .input-label-rupees {
    left: 77%;
  }
  .input-label-ktt {
    left: 72%;
  } */

  .production-title {
    transform: translate(22px, 50px);
    color: #1c407b;
  }

  .production-box {
    margin-top: 240px;
    left: 6%;
    width: 49%;
  }
}

/* @media only screen and (min-width: 200px) and (max-width: 767px) {
  .production-data {
    padding: 40px;
    border-radius: 8px;
    background: white;
    width: 100%;
  }

  .production-data .single-data-item,
  .matching-data-section .single-data-item {
    border-right: 0;
  }

  .production-data .single-data-item,
  .matching-data-section .single-data-item {
    border-right: 0;
  }

  .production-data-table {
    padding: 40px;
    border-radius: 8px;
    background: white;
    width: 100%;
  }
} */

/* @media (max-width: 1199.98px) {
  .production-data-table {
    padding: 40px;
    border-radius: 8px;
    background: white;
    width: 100%;
  }

  .dispatch-data {
    gap: 20px;
    flex-wrap: wrap;
  }

  .production-data-table1 {
    width: 100%;
  }

  .grid-container {
    flex-wrap: wrap !important;
    gap: 0px !important;
  }

  .production-title {
    transform: translate(22px, 50px);
    color: #1c407b;
  }

  .production-box {
    margin-top: 240px;
    left: 6%;
    width: 49%;
  }
} */
@media (min-width: 1025px) and (max-width: 1200px) {
  /* .editCard {
    width: 650px;
  } */

  /* .editData {
    left: 27%;
  } */
}

@media (min-width: 1199.98px) and (max-width: 1536px) {
  .form-input-production-pairs {
    width: calc(100% - 9%) !important;
  }

  .form-input-production-pairs-hours {
    width: 136px;
  }

  .form-input-production {
    width: 135px;
  }

  .form-input-production-footprint {
    width: calc(100% - 9%) !important;
  }

  .pData2 {
    margin-left: -8px !important;
  }

  .pData1 {
    margin-left: -20px !important;
  }

  .pData0 {
    margin-left: -18px !important;
  }

  .Analytics-deviceModal-popup {
    width: 472px;
    height: 636px;
    background: #ffffff;
    box-shadow: 0px 12px 30px #6e6e6e75;
    border-radius: 6px;
    position: fixed;
    top: 12%;
    left: 38%;
    z-index: 1;
  }

  .header-btn-date {
    margin-right: 13px;
    width: 37%;
  }

  .header-btn-month {
    /* width: 37%; */
  }

  /* .editCard {
    width: 638px !important;
  } */

  /* .editData {
    left: 32% !important;
  } */

  .production-title {
    transform: translate(13px, 49px);
    color: #1c407b;
  }
}

@media(max-width:1024px) {
  .slotBox-data {
    height: 330px;
  }

  .slotBox-wrap {
    gap: 20px;
  }
}

@media(max-width:899px) {
  .slotBox-data {
    height: 320px;
    padding: 20px 12px 25px 12px;
  }

  .slotbox-timer {
    gap: 14px;
  }

  .slotbox-timer h6 {
    margin: 0;
  }

  .slotBox-wrap {
    gap: 25px;
  }

  .slotbox-input {
    width: 65px;
    height: 55px;
  }

  .slotBox {
    min-width: 375px;
  }

  .analytics-btn-wrap {
    width: 100%;
  }

  .analytics-cancle-btn {
    letter-spacing: 0.5px;
    width: 100%;
  }

  .analytics-submit-btn {
    letter-spacing: 0.5px;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .dispatch-data {
    /* flex-wrap: wrap; */
  }

  .grid-container {
    flex-wrap: wrap !important;
    gap: 0px;
    padding: 8px 2px;
  }

  .production-data-table {
    padding: 3px 0px !important;
    width: 100%;
    height: 80px;
    border-radius: 6px;
  }

  .pData {
    font-size: 14px;
    font-weight: 500;
  }

  .production-data-table .common-table td,
  .matching-data-section .common-table td:first-child {
    padding: 0 !important;
  }

  .production-data .common-table td,
  .matching-data-section .common-table td:first-child {
    padding: 0px !important;
  }

  .form-input-production-pairs-hours {
    padding: 0px !important;
    width: 100%;
    height: 30px;
    margin-top: 6px;
    font-size: 14px;
  }

  .input-label {
    position: absolute;
    right: 5%;
    top: 10px;
    font-size: 14px;
    font-weight: 500;
  }

  .production-data-table1 {
    /* width: 388px; */
    height: 80px;
    padding: 3px 0px !important;
    border-radius: 6px !important;
  }

  .production-input-color {
    width: 180px;
    height: 30px;
    margin-top: 6px;
    padding-left: 7px !important;
    font-size: 14px;
  }

  .input-label-kt {
    top: 41px;
    font-weight: 500;
    font-size: 14px;
  }

  .overlay1 {
    border-radius: 6px;
  }

  .p-card h4 {
    font-size: 16px;
  }

  .energy-cost-data {
    padding: 0 !important;
    margin-top: 10px;
  }

  .form-input-production {
    width: 180px;
    height: 30px;
    padding-left: 7px !important;
    margin-top: 6px;
    font-size: 14px;
  }

  .input-label-rupees {
    top: 10px;
    font-weight: 500;
    font-size: 14px;
  }

  .edit-production-img {
    height: 20px;
    width: 20px;
  }

  .single-data-item {
    padding-bottom: 0;
  }

  .pData {
    margin-bottom: 5px;
  }

  .production-data {
    padding: 3px 0px !important;
    border-radius: 6px;
  }

  .form-input-production-footprint {
    width: 180px;
    height: 30px;
    padding-left: 7px !important;
    margin-top: 6px;
    font-size: 14px;
  }

  .input-label-ktt {
    top: 10px;
    font-weight: 500;
    font-size: 14px;
  }

  .pro-btn {
    font-size: 14px;
    font-weight: 800 !important;
  }

  .energy-btn {
    font-size: 14px;
    font-weight: 800 !important;
  }

  .co2-btn {
    font-size: 14px;
    font-weight: 800 !important;
  }

  .production-btn {
    padding: 4px !important;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 12px;
  }

  .header-btn-group {
    justify-content: end;
    width: 100%;
    margin-top: 5px;
  }

  .analytics-header {
    flex-wrap: wrap;
    gap: 20px;
  }

  .excel-img-data {
    height: 37px;
    width: 37px;
  }

  .analytics-production-btn {
    margin-top: 6px;
    flex-wrap: wrap;
  }

  .page-header-analytics {
    flex-wrap: wrap;
  }

  .availabilty-tabs {
    width: 104px !important;
    height: 36px;
    padding: 0 !important;
    font-size: 12px !important;
  }

  .repo-tabs {
    width: 104px !important;
    height: 36px;
    padding: 0 !important;
    font-size: 12px !important;
  }

  .energy-img {
    height: 20px !important;
    width: 20px !important;
  }


  .customTimePicker input {
    padding: 8px !important;
    font-size: 14px;
  }

  .customTimePicker {
    min-width: 80px !important;
  }

  .customTimePicker svg {
    width: 15px;
  }

  .slotbox-timer canvas {
    height: 45px;
  }

  .slotBox {
    min-height: 175px;
    min-width: 375px;
  }

  .slotBox-data {
    height: 165px;
  }

  .slotBox-data {
    padding: 12px 12px 0px 12px;
  }

  .editdata-title h6 {
    font-size: 15px;
    margin: 0;
  }

  .slotbox-timer h6 {
    font-size: 12px;
    margin: 0;
  }

  .slotbox-input {
    width: 50px;
    height: 35px;
  }

  .editdata-title {
    margin-bottom: 16px;
  }

  .analytics-btn-wrap {
    width: 100%;
  }

  .analytics-cancle-btn {
    font-size: 14px;
    padding: 8px 50px;
    letter-spacing: 0.5px;
    width: 100%;
  }

  .analytics-submit-btn {
    font-size: 14px;
    padding: 8px 50px;
    letter-spacing: 0.5px;
    width: 100%;
  }

  .editData {
    width: 95%;
  }
  .ana-popup{
    width: 90% !important;
  }

  .date-picker-production input {
    /* padding: 10px; */
    font-size: 12px;
  }

  .end-date-datepicker {
    flex-wrap: unset !important;
  }

  .edit-icon {
    height: 20px;
    width: 20px;
  }

}

@media (min-width: 200px) and (max-width: 400px) {
  .pData0 {
    margin-left: -22px !important;
    margin-bottom: 6px;
  }
}

@media (min-width: 630px) and (max-width: 767px) {
  .pData0 {
    margin-left: -80px !important;
    margin-bottom: 6px;
  }
}

@media(max-width:576px) {
  .customTimePicker input {
    padding: 8px !important;
    font-size: 14px;
  }

  .customTimePicker {
    min-width: 80px !important;
  }

  .customTimePicker svg {
    width: 15px;
  }

  .slotbox-timer canvas {
    height: 40px;
  }

  .slotBox {
    min-height: 80px;
    min-width: 275px;
  }

  .slotbox-input {
    width: 50px;
    height: 35px;
  }

  .slotBox-data {
    height: 118px;
  }

  .slotbox-timer {
    gap: 5px;
  }

  .slotBox-data {
    padding: 12px 12px 0px 12px;
  }

  .slotBox-wrap {
    gap: 0px;
  }

  .editdata-title h6 {
    font-size: 15px;
    margin: 0;
  }
  .ana-popup{
    width: 90% !important;
  }
  .slotbox-timer h6 {
    font-size: 12px;
    margin: 0;
  }

  .editdata-title {
    margin-bottom: 16px;
  }

  .analytics-btn-wrap {
    width: 100%;
  }

  .analytics-cancle-btn {
    font-size: 14px;
    padding: 8px 50px;
    letter-spacing: 0.5px;
    width: 100%;
  }

  .analytics-submit-btn {
    font-size: 14px;
    padding: 8px 50px;
    letter-spacing: 0.5px;
    width: 100%;
  }

  .editData {
    width: 95%;
  }

  .date-picker-production input {
    /* padding: 10px; */
    font-size: 12px;
  }

  .date-picker-production svg {
    width: 15px;
  }

  .edit-icon {
    height: 15px;
    width: 15px;
  }

  .dispatch-data {
    flex-wrap: wrap;
  }

  .sticky-page {
    position: sticky;
    margin-bottom: 25px;
  }

  .confi-analytics {
    height: 500px;
    overflow: scroll;
  }
  .production-dounght-chart{
    flex-wrap: wrap;
  }

}
.pro-unit{
  font-size: 13px !important;
  position: absolute;
  top: 50%;
  left: 27%;
}