
.card_weather1 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 12px #67676747;
    border: 1px solid #E31E24;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    padding-top: 21px;
    padding-bottom: 20px;
    margin: 0;
    cursor: pointer;
}
.sticky-container {
    position: fixed;
    bottom: -6px;
    margin-bottom: 30px !important;
    /* box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1); */
    z-index: 10; 
    padding: 0px 45px;
    background-color: white;
}
.deshbord_bpcl {
    font-weight: bold; /* Bold text */
    color: #333; /* Dark text color */
    margin: 0;
}
.bpcl-pro-dashboard-btns{
    height: 70px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 6px 12px #67676747;
border: 1px solid #E31E24;
border-radius: 4px;
width: 100%;
font-size: 20px;
letter-spacing: 0.6px;
color: #001323;
font-weight: 600;
}