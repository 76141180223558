body {
    font-family: 'Roboto', sans-serif;
}

.main-container {
    min-height: 100vh;
    background-color: #fff;
}


/* ========== SignIn-form-css start ========== */

.signIn-form-wrap {
    padding: 70px 40px 15px 40px;
    position: relative;
}

.signIn-logo {
    margin: 0 auto;
    display: flex;
    height: 77px;
    /* width: 100px; */
}

.singIn-title {
    padding-top: 40px;
    padding-bottom: 25px;
}

.viewIcon {
    position: absolute;
    top: 47.80%;
    right: 8%;
}

.SignIn-keepLogin-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 30px;
}

.keepLogin-wrap {
    display: flex;
    align-items: center;
    /* gap: 10px; */
}


/* .keepLogin-label {
    font-size: 18px;
} */

.keepLogin-label.login {
    font-size: 16px;
}


/* input[type="checkbox"]::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    vertical-align: middle;
    background-color: var(--checkbox-color, #000);
    border-radius: 2px;
    border: 1px solid #ccc;
}

input[type="checkbox"]:checked::before {
    content: '\2713';
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    background-color: var(--checkbox-color, #000);
} */


/* .keepmeLogin-checkBox::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    vertical-align: middle;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #000 !important;
} */


/* .keepmeLogin-checkBox:checked::before {
    content: '\2713';
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    background-color: #ff0000;
} */

.forgotPassword-link {
    font-size: 16px;
}

.forgotPassword-link,
.forgotPassword-link:hover {
    color: #001323;
}

.SingIn-unlock {
    /* padding: 15px 0; */
    /* padding: 15px 0px 25px 0px; */
    padding: 15px 0px 94px 0px;
    /* gap: 10px; */
    gap: 4px;
    font-weight: 500;
    /* margin-bottom: 40px; */
}
.SingIn-unlock{
    font-size: 14px !important;
}
.footer-content {
    position: absolute;
    bottom: 0;
    text-align: left;
    font-size: 14px;
  }
.signIn-Footer {
    display: flex;
    justify-content: space-between;
    /* gap: 14px; */
    /* padding: 30px 0px 40px 0px; */
    /* position: absolute;
    bottom: 90px; */
}

.signIn-Footer h6 {
    /* font-size: 14px; */
    font-size: 12px;
    font-weight: 400;
}

.signIn-footer-lines {
    /* position: absolute;
    bottom: 8px; */
    margin-top: 8px;
}

.signIn-footer-lines h6 {
    font-weight: 400;
    /* font-size: 14px; */
    font-size: 11px;
    /* position: absolute; */
    margin: 0;
}

.signIn-footer-lines span {
    font-weight: 600;
    font-size: 14px;
}

.signIncheckBox {
    border-radius: 10px !important;
    /* color: #e31e24; */
    margin-right: 0;
    padding: 0px !important;
    padding-right: 10px !important;
}

@media(max-width: 1900px) {
    /* .SingIn-unlock {
        padding-bottom: 60px !important;
    } */
}

@media(max-width:1025px) and (max-width:1170px) {
    .signIn-Footer {
        gap: 2px !important;
    }
}

@media(min-width:899.98px) and (max-width:1024px) {
    .signIn-form-wrap {
        padding: 30px 40px 5px 40px !important;
    }
    .signIn-Footer {
        flex-direction: column;
        gap: 0px !important;
        /* padding: 0px 0px 40px 0px !important; */
        bottom: 95px;
    }
    .SingIn-unlock {
        margin-bottom: 14px;
    }
}

@media (min-width: 767px) and (max-width: 899.98px) {
    .signIn-form-wrap {
        /* padding: 70px 40px 175px 40px !important; */
        padding: 70px 40px 15px 40px !important;
    }
    .signIn-Footer {
        gap: 65px !important;
        /* padding: 0px 0px 40px 0px !important; */
        padding: 0px 0px 0px 0px !important;
    }
}


/* ========== SignIn-form-css end ========== */

@media(max-width:576px) {

  
    .viewIcon {
        top: 42.8% !important;
        right: 15% !important;
    }
    /* .signIn-Footer {
        flex-direction: column;
        gap: 5px !important;
        padding: 0px 0px 40px 0px !important;
    } */
}