.Bpcl-page-wrapper {
  padding: 22px 45px;
}
.location-box {
  background-color: #ebebeb;
  border-radius: 4px;
  height: 800px;
  padding: 15px;
}
.map-box {
  background-color: white;
  border-radius: 4px;
  height: 800px;
}
.solar-div {
  border: 1px solid #103168;
  background: #ffffff;
}
.solar-div-data h6 {
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 3px;
  text-align: center;
  font-weight: 600;
}
.solar-div-data p {
  font-size: 12px;
  margin-bottom: 0px;
}
.location-plant {
  background-color: #ffffff;
  border: 1px solid #fe884b;
  border-radius: 4px;
  padding: 10px 12px;
  cursor: pointer;
}
.location-div {
  height: 700px;
  overflow: auto;
}
.gen-dividerLine {
  height: 18px;
  border-left: 2px solid #fe884b;
  margin-right: 5px;
  margin-left: 5px;
}
.uti-dividerLine {
  height: 18px;
  border-left: 2px solid #0c6591;
  margin-right: 5px;
  margin-left: 5px;
}
.cost-dividerLine {
  height: 18px;
  border-left: 2px solid #9933ff;
  margin-right: 5px;
  margin-left: 5px;
}
.co2-dividerLine {
  height: 18px;
  border-left: 2px solid #50c878;
  margin-right: 5px;
  margin-left: 5px;
}
.map-div {
  height: 550px;
}
.responsive-map {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.responsive-map iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.analylize-sec {
  display: flex;
  justify-content: end;
  gap: 12px;
}
.analyze-img {
  height: 22px;
  cursor: pointer;
}
.analyze-div {
  padding: 10px 30px;
}
.generation-div {
  padding: 8px 14px;
  width: 180px;
  border-radius: 0px 20px 20px 0px;
  background-color: #ffaa32;
  color: white;
  text-align: center;
  margin-bottom: 10px;
}
.utilization-div {
  padding: 8px 14px;
  width: 180px;
  border-radius: 0px 20px 20px 0px;
  background-color: #0c6591;
  color: white;
  text-align: center;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}
.cost-div {
  padding: 8px 14px;
  width: 180px;
  border-radius: 0px 20px 20px 0px;
  background-color: #9933ff;
  color: white;
  text-align: center;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}
.BPCL-green {
  padding: 8px 14px;
  width: 180px;
  border-radius: 0px 20px 20px 0px;
  background-color: #50c878;
  color: white;
  text-align: center;
  margin-bottom: 10px;
}
.generation-divider {
  border-right: 1px solid #ebebeb;
  height: 116px;
}
.analyze-div h1 {
  font-size: 47px;
  margin-bottom: 0;
  font-weight: 700;
}
.gen-div {
  text-align: center;
}
.analyze-div p {
  margin-bottom: 4px;
}
.upArrowImg {
  height: 44px;
  margin-top: 5px;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.filter-box {
  height: 196px;
  width: 310px;
  border-radius: 4px;
  background-color: white;
  left: 176px;
  position: absolute;
  padding: 15px;
  top: 23%;
  z-index: 99;
}
.close-img {
  width: 22px;
  cursor: pointer;
  background-color: #fff;
  float: right;
  margin-right: -10px;
  margin-top: -12px;
}

.close-img-map {
  width: 22px;
  cursor: pointer;
  background-color: #fff;
  float: right;
}
.color-data {
  margin-left: 30px !important;
  display: flex;
  cursor: pointer;
}

.dropdown-btn {
  background-color: #e8eaf6;
  color: #6a1b9a;
  font-size: 20px;
  padding: 10px;
  border: 2px solid #6a1b9a;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #e8eaf6;
  min-width: 300px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: 2px solid #6a1b9a;
  padding: 10px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.filter-item,
.efficiency-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ede7f6;
  padding: 10px;
  margin-bottom: 8px;
  border-radius: 4px;
}

.filter-text {
  color: #6a1b9a;
  font-size: 18px;
}

.arrow {
  font-size: 24px;
  color: #6a1b9a;
}

.efficiency-bar {
  padding: 5px 0;
}

.efficiency-bar .color-box {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  border-radius: 2px;
}
.bpcl-search {
  background-color: white;
}
.green-box {
  background-color: green;
}

.orange-box {
  background-color: orange;
}

.red-box {
  background-color: red;
}
.green-square {
  height: 20px;
  width: 20px;
  background-color: #68f195;
  margin-left: 45px;
}
.orange-square {
  height: 20px;
  width: 20px;
  background-color: #fe884b;
  margin-left: 15px;
}
.red-square {
  height: 20px;
  width: 20px;
  background-color: #f55a48;
  margin-left: 45px;
}
div#demo-simple-select-bpcl {
  display: flex;
}

@media(min-width:1537px) and (max-width:1920px){
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  top: 163px !important;
  left: 1613px !important;
}
/* .solar-grid-box{
  width: 25%;
} */
}
@media(max-width:1536px){
  .filter-box {
    height: 196px;
    width: 310px;
    border-radius: 4px;
    background-color: white;
    left: 75px;
    position: absolute;
    padding: 15px;
    top: 30%;
    z-index: 99;
}
}
