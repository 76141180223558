.footer-Text-details {
    background-color: "#F1F1FF";
    color: "#4D5963";
    text-align: "end";
    margin: "0";
    position: "fixed";
    bottom: "0px";
    right: "0";
    padding: "1px";
    width: "100%";
    z-index: "99";
    padding-right: "23px";
    justify-content: "space-between";
    display: "flex";
}

.footer-text {
    background-color: "#F1F1FF";
    color: "#4D5963";
    text-align: "end";
    margin: "0";
    position: "fixed";
    bottom: "0px";
    right: "0";
    padding: "1px";
    width: "100%";
    z-index: "99";
    padding-right: "23px";
}

@media(max-width: 575px) {
    .footer-text-title {
        font-size: 10px;
        text-align: center;
        padding: 10px;
    }
}