.sensor-collapse-table td{
    padding: 15px;
}
.sensor-collapse-table tr:nth-child(even){
background-color: #F4F7FE !important;
}
@media(min-width:1024px) and (max-width:1320px){
    .sensor-collapse-table td:nth-child(1) {
        width: 62px;
      }
      .sensor-collapse-table td:nth-child(2) {
        width: 169px;
      }
      .sensor-collapse-table td:nth-child(4) {
        width: 112px;
      }
      .sensor-collapse-table td:nth-child(5) {
        width: 182px;
      }
}

@media(min-width:1321px) and (max-width:1536px){
  .sensor-collapse-table td:nth-child(1) {
    width: 64px !important;
  }
  .sensor-collapse-table td:nth-child(2) {
    width: 185px;
  }
  .sensor-collapse-table td:nth-child(4) {
    width: 115px;
  }
  .sensor-collapse-table td:nth-child(5) {
    width: 187px;
  }
}

@media(min-width:1536px) and (max-width:1920px){
    .sensor-collapse-table td:nth-child(1) {
        width: 91px;
      }
      .sensor-collapse-table td:nth-child(2) {
        width: 264px;
      }
      .sensor-collapse-table td:nth-child(4) {
        width: 164px;
      }
      .sensor-collapse-table td:nth-child(5) {
        width: 267px;
      }
}
@media(min-width:767px) and (max-width:1024px){
    .sensor-collapse-table td:nth-child(1) {
        width: 62px;
      }
      .sensor-collapse-table td:nth-child(2) {
        width: 132px;
      }
      .sensor-collapse-table td:nth-child(4) {
        width: 112px;
      }
      .sensor-collapse-table td:nth-child(5) {
        width: 182px;
      }
}