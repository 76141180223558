.generation-data-div {
  padding: 40px 40px 0px 40px;
  display: flex;
  justify-content: space-between;
}

.gen-data-unit {
  font-size: 30px !important;
  font-weight: 600;
  color: #1c407b;
}
.gen-value span {
  font-size: 15px;
  margin-left: 6px;
  color: #1c407b;
}
.gen-value-heading{
  color: #1c407b;
  font-weight: 700;
}
.notFoundDataImg-analyze{
  margin-left: auto;
    margin-right: auto;
    display: block;
    transform: translate(7px, -2px);
    height: 158px;
    width: 207px;
}


.gen-div-line {
  border-right: 1px solid #ebebeb;
  height: 50px;
}
.generation-pie-bar-chart-div {
  padding: 20px;
}
.barChart-box {
  border: 2px dashed #1c407b;
  height: 520px;
  border-radius: 4px;
}
.bpcl-chart-box {
  height: 50px;
}
.chart-title p {
  text-align: center;
  font-weight: 600;
}
.pie-chart-box-data {
  padding: 0px 10px;
  height: 155px;
  overflow: auto
}
.pie-chart-box-data p {
  font-size: 15px;
}
.bpcl-line-chart{
  padding: 15px;
}
.bpcl-report-tab th{
padding: 0px 0px !important;
}

.bpcl-report-tab td{
  font-size: 14px;
  }

.line-ico-img {
  float: right;
  margin-top: -25px;
  margin-right: -60px;
}


.table-cell-div {
  padding-top: 20px;
}  
@media(max-width:1536px){
  .truncated {
    white-space: nowrap;        
    overflow: hidden;           
    text-overflow: ellipsis;    
    max-width: 104px;         
  }
}