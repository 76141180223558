.cost-data {
  background: white;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 119px;
}
.cost-box {
  border: 1px solid #a9b0bd;
  padding: 10px;
  width: auto;
  height: 40px;
  border-radius: 6px;
}

.cost_bottom {
  margin-bottom: 91px;
}

@media only screen and (min-width: 768px) and (max-width: 1360px) {
.cost-top{
  margin-bottom: 20px;
}
.cost-box{
  margin-bottom: 30px;
}
}


@media(min-width: 1024px) and (max-width: 1536px) {
  p.total-co {
    font-size: 14px;
  }
}

