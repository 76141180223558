.subscriptionTitle-wrap {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.subscriptionTitle-wrap .backArrow {
  cursor: pointer;
}
.sub-btn {
  padding: 10px 24px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #475467;
  border-radius: 4px;
  opacity: 1;
}
.addsmartTag-titleWrap {
  padding: 0;
  text-align: center !important;
  background-color: #1c407b;
  padding: 17px 0px 3px 0px;
}
.total-price-title{
  font-size: 14px;
}
.total-price{
  font-size: 16px;
    font-weight: 900;
}
.plus-sign{
  padding: 11px;
  font-size: 21px;
}
.smart-card {
  width: 240px;
  height: 310px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 20px #aaaaaa29 !important;
  border-radius: 8px !important;
  opacity: 1;
}
.selected {
  border: 2px solid #1c407b !important;
}

.smart-tag-card {
  width: 240px;
  height: 310px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 20px #aaaaaa29;
  opacity: 1;
  border: 2px solid #1c407b !important;
}
.subscription-header {
  display: flex;
  align-items: center !important;
  margin-top: 20px;
  margin-bottom: 20px;
}

.subscription-button {
  background: #f1f1ff 0% 0% no-repeat padding-box;
  border: 1px solid #aebcd2;
  color: #9a9fa7;
  border-radius: 8px 0px 0px 0px;
  opacity: 1;
  /* width: 180px;
  height: 50px; */
  padding: 13px 20px;
  border-bottom: none;
}

.editsubscription-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #475467;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.48px;
  color: #3c4856;
  width: 140px;
  height: 46px;
}
.editDisable-btn{
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.48px;
  color: #3c4856;
  width: 140px;
  height: 46px;
  opacity: 0.6;
  pointer-events: none;
}
.editsub-btn {
  background: #f1f1ff;
  border: 1px solid #001323;
  border-radius: 4px;
  width: 170px;
  height: 46px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.48px;
  color: #001323;
  margin-right: 20px;
}
.action-delete {
  opacity: 0.3;
  pointer-events: none;
}
.activesub-btn {
  background: #dffee9;
  border: 1px solid #348750;
  border-radius: 23px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.48px;
  color: #0d6329;
  width: 140px;
  height: 46px;
}
.expiredsub-btn {
  background: #ffccce;
  border: 1px solid #e31e24;
  border-radius: 23px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.48px;
  color: #e31e24;
  width: 140px;
  height: 46px;
}
.not-activated-btn{
  background: #EEEEEE;
  border: 1px solid #475467;
  border-radius: 23px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.48px;
  color: #475467;
  width: 140px;
  height: 46px;
}
.expiringdsub-btn {
  background: #fff2e1;
  border: 1px solid #ffaa32;
  border-radius: 23px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.48px;
  color: #ffaa32;
  width: 160px;
  height: 46px;
}
.subconnection {
  margin-right: 15px;
  margin-top: 11px;
}
.subscription-button-dark {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #aebcd2;
  color: #001323;
  border-radius: 8px 0px 0px 0px;
  opacity: 1;
  /* width: 180px;
  height: 50px; */
  padding: 13px 20px;
  font-weight: 500;
  border-bottom: none;
}

.payment-button {
  background: #f1f1ff 0% 0% no-repeat padding-box;
  border: 1px solid #aebcd2;
  color: #9a9fa7;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  /* width: 180px;
  height: 50px; */
  padding: 13px 20px;
  border-bottom: none;
}
.payment-button-dark {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #aebcd2;
  color: #001323;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  padding: 13px 20px;
  /* width: 180px;
  height: 50px; */
  border-bottom: none;
}
.subscriptionTable tr {
  border: none !important;
}
.subscriptionTable {
  border-collapse: separate;
  border-spacing: 0;
}

.subscriptionTable thead th {
  padding-bottom: 10px; /* Adjust the desired spacing value */
  border: none !important;
}

.subscriptionTable tbody td {
  padding-top: 10px; /* Adjust the desired spacing value */
}
.subscriptionTable thead {
  position: sticky;
  top: 0;
  z-index: 99;
}
.subscriptionTable td {
  border: none !important;
  padding: 12px !important;
}

.subscriptionTable tr td {
  border: none !important;
  font-size: 17px;
  letter-spacing: 0.6px;
  color: #3c4856;
  /* background-color: white; */
}
.subscriptionTable th {
  letter-spacing: 0px;
  color: #001323;
  font-weight: 500;
}

.subscription-table {
  border: 1px solid #a9b0bd;
  border-radius: 0px 8px 8px 8px;
  height: 634px;
  overflow: auto;
  background: white;
}
.invoice-button {
  background: #f1f1ff 0% 0% no-repeat padding-box;
  border: 1px solid #aebcd2;
  color: #9a9fa7;
  border-radius: 0px 8px 0px 0px;
  opacity: 1;
  /* width: 140px;
  height: 50px; */
  padding: 13px 20px;
  border-bottom: none;
}
/* .subscription-table tr td{
  border: none !important;
} */

.red-license {
  background: #e31e24 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 20px;
  height: 20px;
}
.active-license {
  background: #50c878 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 20px;
  height: 20px;
}
.inprogress-license{
  background:#bd9c39 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 20px;
  height: 20px;
}
.expiring-license {
  background: #f7c51e 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 20px;
  height: 20px;
}
.smartTag-rupees {
  margin-left: 9px;
  font-size: 14px;
}
.free-license {
  background: #a9b0bd 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 20px;
  height: 20px;
}
.subscription-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #475467;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.48px;
  color: #3c4856;
  width: 180px;
  height: 46px;
}

.subscriptionCard {
  /* position: relative; */
  height: 620px;
  /* width: 450px !important; */
  /* width: auto !important; */
}

.subscriptionCard-Image {
  position: relative;
  width: 100%;
}
.smarTag-modal {
  background-color: #50c878;
  padding: 5px 6px 4px 2px;
  /* padding: 4px; */
  /* padding: 2px 18px 2px 18px; */
  border-radius: 50px;
  color: #fff;
  text-align: center;
  /* position: absolute; */
  top: 8%;
  left: 50%;
  transform: translate(29%, 15%);
  /* margin-top: -12px; */
  width: 64%;
  margin-bottom: 13px;
}
.smarTag-Details {
  font-weight: 500 !important;
  margin-bottom: 0 !important;
}
.payment-button-smartTag {
  display: flex;
  gap: 20px;
  justify-content: center;
}
.smartTag-total {
  margin-bottom: 0 !important;
}
.Back-button-smarTag {
  background: #e5e5e5 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 100px;
  letter-spacing: 0.6px;
  color: #1c407b !important;
  font-weight: 500;
  padding: 12px;
  border: none;
  font-size: 19px;
}
.smartTag-checkIcon {
  margin-left: 39px;
}
.modal-check-css {
  margin-left: 31px;
}
.smartTag-grid {
  display: flex;
  width: fit-content;
  flex-wrap: wrap;
  gap: 20px;
}
.smartTag-title {
  text-align: center;
  font: normal normal normal 16px/19px Roboto;
  letter-spacing: 0px;
  color: #a9b0bd;
  display: block;
}
.css-yafthl-MuiSlider-markLabel {
  font-weight: 500 !important;
  font-size: 20px !important;
  color: black !important;
}
.css-1eoe787-MuiSlider-markLabel {
  font-weight: 500 !important;
  font-size: 20px !important;
  color: black !important;
}

.Contact-us-button-smartTag {
  border: 1px solid #1c407b;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #1c407b;
  border-radius: 4px;
  letter-spacing: 0.6px;
  color: #1c407b;
  font-weight: 500;
  padding: 12px 24px;
}
.saved-smartTag {
  background: #50c878 0% 0% no-repeat padding-box;
  border-radius: 100px;
  color: #ffffff;
  padding: 10px 20px;
  align-self: center;
}
.imageWrap-subscription {
  position: absolute;
  /* top: 40%; */
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 50px;
  border-radius: 50%;
  padding: 60px;
  box-shadow: 0px 12px 20px #00000029;
}

.sub-footertext {
  display: flex;
  gap: 10px;
  margin-left: 20px;
}

.active-plan {
  width: 16px;
  height: 16px;
  background: #50c878;
  border-radius: 50%;
  margin-top: 2px;
}
.expiring-plan {
  width: 16px;
  height: 16px;
  background: #ffaa32;
  border-radius: 50%;
  margin-top: 2px;
}
.expired-plan {
  width: 16px;
  height: 16px;
  background: #e31e24;
  border-radius: 50%;
  margin-top: 2px;
}
.not-activated-plan {
  width: 16px;
  height: 16px;
  background: #a9b0bd;
  border-radius: 50%;
  margin-top: 2px;
}
.expiring-soon-plan {
  width: 16px;
  height: 16px;
  background: #ffaa32;
  border-radius: 50%;
}

.expired-plan {
  width: 16px;
  height: 16px;
  background: #e31e24;
  border-radius: 50%;
}

.free-limited-trial-plan {
  width: 16px;
  height: 16px;
  background: #a9b0bd;
  border-radius: 50%;
}


.subscriptionContent {
  /* padding-top: 50px; */
}

.subscription-title-name {
  text-align: center;
  color: #001323;
  font-weight: 600 !important;
}

.subscription-text {
  text-align: center;
}

.subscription-details {
  color: #001323;
  font-weight: 400 !important;
  margin-bottom: 0 !important;
}

.checkIconbg {
  background-color: transparent !important;
}

.checkIcon {
  background-color: #50c878;
  border-radius: 50%;
}
.payment-card {
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #aebcd2;
  border-radius: 8px;
}
.payment-grid {
  display: flex;
  flex-direction: column;
  gap: 13px;
}
.name-card .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12px !important;
}
.paymentcard-button {
  background: #f1f1ff 0% 0% no-repeat padding-box;
  border: 1px solid #a9b0bd;
  border-radius: 4px;
  width: 160px;
  height: 46px;
  font-weight: 500;
}
.save-button-payment {
  background: #1c407b 0% 0% no-repeat padding-box;
  border: 1px solid #123063;
  border-radius: 4px;
  width: 162px;
  height: 46px;
  text-align: center;
  font: normal normal bold 16px/19px Roboto;
  letter-spacing: 0.48px;
  color: #ffffff;
}
@media (min-width: 1640px) and (max-width: 1895px) {
  .subscriptionCard {
    width: auto !important;
  }
}

@media (min-width: 1025px) and (max-width: 1640px) {
  .subscriptionCard {
    width: 408px !important;
  }
  .name-card-width {
    width: 50ch !important;
  }
  .company-input-details {
    width: 38ch !important;
  }
  .email-input-details {
    width: 65ch !important;
  }
  .city-details {
    width: 32ch !important;
  }
  .code-details {
    width: 31ch !important;
  }
  .email-input-details-billing {
    width: 52ch !important;
  }
}

@media (min-width: 899.98px) and (max-width: 1024px) {
  .subscriptionCard {
    width: auto !important;
  }
}

@media (min-width: 767px) and (max-width: 899.98px) {
  .subscriptionCard {
    width: auto !important;
  }
  .name-card-width {
    width: 26ch !important;
  }
  /* .name-card{
    width: 26ch;
  } */
  .company-input-details {
    width: 21.5ch !important;
  }
  .email-input-details {
    width: 45ch !important;
  }
  .city-details {
    width: 21ch !important;
  }
  .code-details {
    width: 22ch !important;
  }
  .email-input-details-billing {
    width: 38ch !important;
  }
}
.iii {
  margin-top: 50px;
}
.main_div {
  /* height: 320px;
  background-color: #ccc; */
  position: relative;
  width: 243px;
  cursor: pointer;
}

.inner_div {
  background: #f7c51e 0% 0% no-repeat padding-box;
  border-radius: 100px;
  position: absolute;
  padding: 4px 0px;
  top: 00%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 209px;
  height: 26px;
}
.more-btn{
  margin-left: 18px;
  padding: 6px 13px;
  background: #bfd3f3;
  border: #bfd3f3;
  border-radius: 4px;
}