/* ========== SignIn-form-css start ========== */

.main-container {
    min-height: 100vh;
    background-color: #fff;
}

.createPassword.signIn-form-wrap {
    padding: 40px 40px 15px 40px !important;
    position: relative;
}

.signIn-logo {
    margin: 0 auto;
    display: flex;
    height: 77px;
}

.logo-deafult {
    margin: 0 auto;
    display: flex;
}

.forgotPassword {
    /* font-size: 36px; */
    font-size: 28px;
}

.SingIn-unlock {
    /* padding: 15px 0; */
    padding: 15px 0px 36px 0px;
    /* gap: 10px; */
    gap: 4px;
    font-weight: 500;
}

.signIn-footer {
    gap: 20px;
    /* padding: 30px 0px 40px 0px; */
    padding: 0px 0px 0px 0px;
}

.signIn-footer h6 {
    /* font-size: 14px; */
    font-size: 12px;
    font-weight: 400;
}

.signIn-footer-lines h6 {
    font-weight: 400;
}

.signIn-footer-lines span {
    font-weight: 600;
}

.forgotPassword-lines {
    /* font-weight: 400; */
    /* font-size: 19px; */
    text-align: center;
}


 .forgotPassword-lines h5 {
    font-size: 16px;
}
/* ============================================================== */
.createPassword-text{
    text-align: center;
    padding-bottom: 30px;
    font-weight: 600;
}
.createPasswordText{
    padding: 20px 0px 40px 0px;
    font-size: 30px;
}
.createPassword-button{
    background-color: #F4F7FE;
    color: #000;
    border: 1px solid #1E3760;
    border-radius: 6px;
    width: 100%;
    padding: 12px;
    text-transform: capitalize;
} 
.createPassword-button.getOTP
{
    background-color: #1C407B;
    color: white;
    border: 1px solid #1E3760 !important;
    border: 0;
    border-radius: 6px;
    width: 100%;
    /* padding: 16px; */
    padding: 12px;
    /* letter-spacing: 1.8px; */
    text-transform: capitalize;
}
.createPassword.signIn-footer-lines{
    /* margin-top: 30px; */
    position: fixed;
    bottom: 10px;
}
.reEnterPassword-TextWrap h6{
    font-size: 11px;
    color: #001323;
}
/* .reEnterPassword-TextWrap{
    margin: 0;
} */
/* ============================================================== */

@media(min-width:1024px) and (max-width:1400px) {
    .forgotPassword {
        font-size: 28px !important;
    }
}

@media(min-width:1025px) and (max-width:1170px) {
    .signIn-Footer {
        gap: 2px !important;
    }
}

@media(min-width:899.98px) and (max-width:1024px) {
    .signIn-form-wrap {
        padding: 30px 40px 5px 40px !important;
    }
    .createPassword.forgotPassword{
        padding-top: 10px;
    }
    .signIn-Footer {
        flex-direction: column;
        gap: 0px !important;
        /* padding: 0px 0px 40px 0px !important; */
        bottom: 95px;
    }
    .forgotPassword-lines h5 {
        /* font-size: 18px; */
        font-size: 15px;
    }
    .forgotPassword {
        font-size: 24px;
    }
    .SingIn-unlock {
        margin-bottom: 14px;
    }
    /* ============================================= */
    .createPassword-text{
        padding-bottom: 30px;
        font-weight: 600;
        font-size: 30px;
    }
}

@media(min-width:1025px) and (max-width:1170px) {
    .signIn-footer {
        gap: 2px !important;
    }
}

@media (min-width: 767px) and (max-width: 899.98px) {
    .signIn-form-wrap {
        /* padding: 70px 40px 175px 40px !important; */
        padding: 70px 40px 15px 40px !important;
    }
    .forgotPassword-lines h5 {
        font-size: 15px !important;
    }
    .signIn-footer {
        /* flex-direction: column; */
        gap: 65px !important;
        /* padding: 0px 0px 40px 0px !important; */
        padding: 0px 0px 0px 0px !important;
    }
}


/* ========== SignIn-form-css end ========== */

@media(max-width:576px) {
    /* .signIn-logo {
        width: 100%;
    } */

    .viewIcon {
        position: absolute;
        top: 42.8% !important;
        right: 15% !important;
    }
 
}