body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1ff !important;
  /* position: relative; */
  z-index: -1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.donut-chart-css {
  height: 195px !important;
}
.css-1km1ehz {
  -webkit-tap-highlight-color: transparent !important;
  background-color: transparent !important;
  outline: 0px !important;
  border: 0px !important;
  margin: 0px !important;
  border-radius: 0px !important;
  cursor: pointer !important;
  user-select: none !important;
  vertical-align: middle !important;
  appearance: none !important;
  color: inherit;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5 !important;
  letter-spacing: 0.00938em !important;
  display: flex !important;
  -webkit-box-pack: start;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  position: relative !important;
  text-decoration: none !important;
  min-height: 48px;
  padding: 6px 16px !important;
  box-sizing: border-box;
  white-space: nowrap !important;
}
.css-r80t5g {
  -webkit-tap-highlight-color: transparent !important;
  background-color: transparent !important;
  outline: 0px !important;
  border: 0px !important;
  margin: 0px !important;
  border-radius: 0px !important;
  cursor: pointer !important;
  user-select: none !important;
  vertical-align: middle !important;
  appearance: none !important;
  color: inherit;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5 !important;
  letter-spacing: 0.00938em;
  display: flex !important;
  -webkit-box-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  position: relative !important;
  text-decoration: none !important;
  min-height: 48px;
  box-sizing: border-box !important;
  white-space: nowrap !important;
  padding: 16px 24px !important;
}
/* .MuiButtonBase-root{
  border: 0 !important; 
  background-color: transparent !important;
} */
.page-wraper {
  padding: 22px 24px 50px 33px !important;
}
.date-picker {
  width: 168px;
}

.range-picker-container .ant-picker-input > input {
  color: black !important;
}
.custom-range-picker .ant-picker-input > input {
  color: black !important;
}

table.transparent-table,
table.transparent-table tr,
table.transparent-table td,
table.transparent-table th {
  background-color: transparent !important;
}
table.no-border-table,
table.no-border-table tr,
table.no-border-table td,
table.no-border-table th {
  border: 0px !important;
}
.form-input-production {
  margin-bottom: 11px !important;
  background-color: #f1f1ff;
  padding: 15px;
  border: none;
  border-radius: 4px;
  opacity: 1;
  width: 90% !important;
}
.css-gqssyb {
  min-width: 0 !important;
}
.react-datepicker__header {
  text-align: center;
  background: #1c407b !important;
  color: white !important;
  /* background-color: #f0f0f0; */
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.anticon{
  color: black !important;
}
.custom-range-picker .ant-picker-input input::placeholder {
  color: black !important; /* Sets placeholder text color to black */
  opacity: 1; /* Ensures the placeholder is fully opaque */
}

.react-datepicker__month-text--keyboard-selected {
  background: #1c407b !important;
  color: white !important;
}
.react-datepicker__navigation {
  top: 8px !important;
}
rect.highcharts-button-box {
  display: none;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -6px;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 5px !important;
}
/* index.css */

.range-picker-container {
  display: flex;
  flex-direction: column;
  align-items: end;
  /* padding: 18px; */
  /* margin: 20px; */
}

/* display only one month in rangepicker  */
/* .ant-picker-panels > *:last-child {
  display: none !important;
} */

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important; 
}
path.highcharts-button-symbol {
  display: none;
}
.ant-picker-content tr,
td,
th {
  border: none !important;
}
/* Avoid globally padding-left for datepicker */
.ant-picker-content tr td {
  padding-left: 0px !important;
}
.ant-picker {
  width: 100%;
  max-width: 240px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  transition: border-color 0.3s;
  padding: 7px;
}

.ant-picker:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 5px rgba(64, 169, 255, 0.5);
}

.ant-picker-presets {
  margin: 10px 0;
}

.ant-picker-presets .ant-picker-presets-item {
  color: #1890ff;
  cursor: pointer;
}

/* Change color on hover for preset labels */
.ant-picker-presets .ant-picker-presets-item:hover {
  text-decoration: underline; /* Underline on hover */
}

.popup-box {
  background: white;
  height: 237px;
  width: 23%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hikemm-btn {
  background: #1c407b;
  border: 1px solid #1e3760;
  border-radius: 4px;
  opacity: 1;
  padding: 10px 20px;
  color: white;
}

.vms-btn {
  background: #1c407b;
  border: 1px solid #1e3760;
  border-radius: 4px;
  opacity: 1;
  padding: 10px 33px;
  color: white;
}
.page-btn-consumption {
  color: #001323;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 14px;
  cursor: pointer;
  /* width: 8%; */
}
/* Custom clock CSS */
.custom-clock-container {
  text-align: center;
  position: relative;
}

.custom-clock {
  position: relative;
  width: 60px;
  height: 60px;
  background-color: #f0f0f0;
  border: 2px solid #0077b6;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}

/* Pseudo-element to fill the clock */
.custom-clock::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: var(--fill, 0%);
  height: 100%;
  background-color: #0077b6; /* Color for the filled portion */
  transition: width 0.3s ease; /* Add a transition effect */
}

.clock-canvas {
  height: 60px;
  width: 60px;
}
.MuiFormControl-root.MuiTextField-root.customTimePicker.css-i44wyl {
  min-width: 131px !important;
}
.triangle ::after {
  content: "";
  height: 12px;
  width: 12px;
  position: absolute;
  background-color: #fff;
  top: -7px;
  right: -6px; /* Adjust as needed */
  border-top: black solid 1px;
  border-right: black solid 1px;
  transform: rotate(45deg);
}

.spinner-gif {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: #1c407b;
  font-size: 10px;
  width: 1em;
  height: 1em;
  text-indent: -9999em;
  animation: mulShdSpin 1.3s infinite linear;
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
.spinner-gifBox {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 48%;
  left: 41%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: inline-block;
  border-top: 8px solid #e31e24;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.spinner-gifBox::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-left: 8px solid #1c407b;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
.gm-style .gm-style-iw-tc::after {
  background: #fff;
  -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  content: "";
  height: 12px;
  left: 0;
  position: absolute;
  top: -42px;
  width: 25px;
}
.gm-style-iw.gm-style-iw-c {
  margin-top: -42px;
  width: 332px;
}
.gm-ui-hover-effect {
  display: none !important;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes shimmer {
  from {
    background-position: var(--percentage) 0;
  }
  to {
    background-position: calc(var(--percentage) * -1) 0;
  }
}

.shimmer-linechart {
  animation: shimmer 2s linear infinite;
  background: #f0f0f0
    linear-gradient(
      to right,
      transparent 0%,
      rgba(0, 0, 0, 0.2) 25%,
      transparent 50%
    );
  background-size: var(--percentage) 100%;
  height: 368px;
  margin-right: 20px;
  margin-left: 20px;
}
/* For vertical scrollbar */
/* Track */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* For horizontal scrollbar */
/* Track */
::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb:horizontal {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:horizontal:hover {
  background: #555;
}

.shimmer-line {
  position: absolute;
  top: 0;
  left: -100%; /* Initially start from outside the container */
  width: 200%; /* Twice the width of the container to cover the entire canvas */
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer-animation 2s infinite linear; /* Adjust animation duration */
}

@keyframes shimmer-animation {
  100% {
    transform: translateX(100%);
  }
}

.chart-container-line-chart {
  position: relative;
  width: 100%;
  overflow: hidden; /* Hide both horizontal and vertical scrollbars */
  height: 400px;
}

canvas {
  display: block;
  /* width: 100%; */
  height: auto;
}
