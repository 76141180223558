.custom-button {
    background-color: #E31E24;
    color: white;
    border: 1px solid #E31E24 !important;
    border: 0;
    border-radius: 6px;
    width: 100%;
    /* padding: 16px; */
    padding: 12px;
    /* letter-spacing: 1.8px; */
    text-transform: capitalize;
}

.border-btn {
    background-color: #F4F7FE;
    color: #000;
    border: 1px solid #E31E24;
    border-radius: 6px;
    width: 100%;
    /* padding: 16px; */
    padding: 12px;
    /* letter-spacing: 1.8px; */
    text-transform: capitalize;
}