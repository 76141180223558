.Device-caption-configration {
  background-color: #ffffff;
  padding: 11px 13px;
  font-size: 15px;
}
.Taglist-checkbox {
  border: none;
}
.Taglist-checkbox td {
  border: none !important;
}
.Taglist-checkbox tr {
  border: none !important;
}
/* .MuiPaper-root.MuiTableContainer-root.overviewtable-data-container.MuiPaper-elevation1.MuiPaper-rounded::before {
  z-index: 99;
  width: var(--scroll-overlay-width, 100%);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #AEBCD2B3;
  content: "";
} */
@media (max-width: 576px) {
  .confi-overview-btn {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  .row p{
   font-size: 14px !important;
  }
  .Device-caption h6 {
    font-size: 14px;
  }
  .Device-caption p{
    font-size: 10px;
  }
}
